import { Authorship } from "./authorship";
import { Award } from "./award";
import { CriticalRole } from "./criticalRole";
import { CurrentVisa } from "./currentVisa";
import { HighCompensation } from "./highCompensation";
import { O1Start } from "./o1Start";
import { OriginalContribution } from "./originalContribution ";
import { PersonalDetails } from "./personalDetails";
import { VisaDocuments } from "./visaDocuments";
import { Judging } from "./judging";
import { Press } from "./press";
import { Membership } from "./membership";
import { AdvisoryOpinionLetter } from "./advisoryOpinionLetter";
import { SupervisorLetter } from "./supervisorLetter";
import { ExpertLetter } from "./expertLetter";
import { JSX } from "react";

export const ONBOARDING_STEP_MAP: Record<string, JSX.Element> = {
  "o1-start": <O1Start />,
  "personal-details": <PersonalDetails />,
  "current-visa": <CurrentVisa />,
  "visa-documents": <VisaDocuments />,
  "critical-role": <CriticalRole />,
  "original-contribution": <OriginalContribution />,
  "high-compensation": <HighCompensation />,
  award: <Award />,
  authorship: <Authorship />,
  judging: <Judging />,
  membership: <Membership />,
  press: <Press />,
  "advisory-opinion-letter": <AdvisoryOpinionLetter />,
  "supervisor-letter": <SupervisorLetter />,
  "expert-letter": <ExpertLetter />,
};
