import z from "zod";

export const formValueStatus = z.enum([
  "not-submitted",
  "submitted",
  "approved",
  "rejected",
]);

export type FormValueStatus = z.infer<typeof formValueStatus>;

export const formValuesSchema = z.record(
  z
    .object({
      status: formValueStatus.optional(),
      rejectionReason: z.string().optional(),
      value: z.any().optional(),
      rejectedValue: z.any().optional(),
    })
    .default({
      status: "not-submitted",
      value: undefined,
      rejectedValue: undefined,
      rejectionReason: undefined,
    })
);

export type FormValues = z.infer<typeof formValuesSchema>;

export const parseFormValues = (data: unknown): FormValues => {
  if (data == null) return {};
  if (typeof data === "string") {
    try {
      return parseFormValues(JSON.parse(data));
    } catch (e) {
      console.error("error parsing form values", data, e);
      return {};
    }
  }
  const parsed = formValuesSchema.safeParse(data);
  if (parsed.success) return parsed.data;

  return {};
};
