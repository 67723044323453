import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { Editor, Extensions, NodeViewProps } from "@tiptap/react";
import { SignatureBox } from "@/lib/extensions/signature";
import {
  getPortalExtensions,
  useCollaborationProvider,
} from "@lighthouse/editor";
import { SYNC_SERVER_BASE_URL } from "@/lib/hooks";

export interface EditorContextType {
  provider: HocuspocusProvider | null;
  editor: Editor | null;
  setEditor: (value: Editor | null) => void;
  extensions: Extensions | null;
  ready: boolean | null;
  focusedCommentId: string | null;
  userId: string;
  userName: string;
  mode: "sign" | "review";
  commentUserIdWhitelist: string[];
  handleFocusComment: (id: string | null, scroll?: boolean) => void;
}

const EditorContext = createContext<EditorContextType>({} as EditorContextType);

export const EditorContextProvider = (props: {
  children: React.ReactNode;
  userId: string;
  userName: string;
  commentUserIdWhitelist: string[];
  letterId: number;
  mode: "sign" | "review";
  letterActionId: number;
  onSignatureAdded?: () => void;
  disableSignatures?: boolean;
}) => {
  const {
    children,
    userName,
    userId,
    commentUserIdWhitelist,
    mode,
    letterId,
    letterActionId,
    onSignatureAdded,
    disableSignatures,
  } = props;

  const [focusedCommentId, setFocusedCommentId] = useState<string | null>(null);

  const [editor, setEditor] = useState<Editor | null>(null);

  const [provider, syncState, _] = useCollaborationProvider({
    type: "letter",
    docId: letterId.toString(),
    token: undefined,
    isLoggedIn: true,
    syncServerUrl: `wss://${SYNC_SERVER_BASE_URL}/collaboration`,
  });

  const handleFocusComment = useCallback(
    (id: string | null, scroll: boolean = true) => {
      setFocusedCommentId(id);

      const highlightedMarks = document.querySelectorAll(
        ".comment-highlight-focused, .comment-highlight-focused-external"
      );

      highlightedMarks.forEach((mark) => {
        requestAnimationFrame(() => {
          mark.classList.remove("comment-highlight-focused");
          mark.classList.remove("comment-highlight-focused-external");
        });
      });

      if (id == null) return;

      const newMarks = document.querySelectorAll(
        `[data-id='${id}'][data-type='comment-highlight']`
      );

      if (newMarks.length === 0) return;

      const internal = newMarks[0].getAttribute("data-internal") === "true";

      newMarks.forEach((mark) => {
        requestAnimationFrame(() => {
          mark.classList.add(
            internal
              ? "comment-highlight-focused"
              : "comment-highlight-focused-external"
          );
        });
      });

      if (!scroll) return;

      requestAnimationFrame(() => {
        newMarks[0]?.scrollIntoView({ behavior: "smooth" });
      });
    },
    [setFocusedCommentId]
  );

  const extensions = useMemo(() => {
    if (provider == null) return [];

    return getPortalExtensions({
      provider,
      comments: {
        handleFocusComment,
      },
      signature: {
        onSignatureAdded,
        signatureBoxComponent: SignatureBox as React.FC<NodeViewProps>,
        disabled: disableSignatures ?? false,
        letterActionId: letterActionId.toString(),
      },
    });
  }, [provider, userName, handleFocusComment, onSignatureAdded]);

  return (
    <EditorContext.Provider
      value={{
        provider,
        extensions,
        ready: syncState === "connected",
        editor,
        setEditor,
        focusedCommentId,
        commentUserIdWhitelist,
        userId,
        userName,
        mode,
        handleFocusComment,
      }}
    >
      <React.Fragment key={letterId}>
        <React.Fragment>{children}</React.Fragment>
      </React.Fragment>
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => useContext(EditorContext);
