import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { DateInput, Input, TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const CriticalRole = () => {
  const { currentOnboardingNodeId } = useOnboarding();

  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  const keyResponsibilities = [
    data.keyProjectsAndResponsibilities?.value?.[0] ?? "",
    data.keyProjectsAndResponsibilities?.value?.[1] ?? "",
    data.keyProjectsAndResponsibilities?.value?.[2] ?? "",
  ];

  const setKeyResponsibility = (index: number, value: string) => {
    doSetData("keyProjectsAndResponsibilities", [
      ...keyResponsibilities.slice(0, index),
      value,
      ...keyResponsibilities.slice(index + 1),
    ]);
  };

  const isDateEmpty = (x: string | null | undefined) => {
    return x == null || x.trim() === "";
  };

  return (
    <OnboardingForm heading="Your work experience">
      <Field id="companyName" label="What is the name of the company?">
        <Input
          type="text"
          placeholder="Company Name"
          value={data.companyName?.value as string}
          onValueChange={(val) => doSetData("companyName", val)}
        />
      </Field>
      <Field
        id="roleTitle"
        label={`What was your role title at ${data.companyName?.value ?? "the company"}?`}
      >
        <Input
          type="text"
          placeholder="Role Title"
          value={data.roleTitle?.value as string}
          onValueChange={(val) => doSetData("roleTitle", val)}
        />
      </Field>

      <Field
        id="datesOfEmployment"
        label="When did you work here?"
        description="If you are currently in this role, please leave the end date blank."
      >
        <div className="flex flex-row gap-1.5 items-center">
          <DateInput
            value={
              !isDateEmpty(data.datesOfEmployment?.value?.startDate)
                ? new Date(data.datesOfEmployment?.value?.startDate)
                : undefined
            }
            onValueChange={(val) =>
              doSetData("datesOfEmployment", {
                ...data.datesOfEmployment?.value,
                startDate: val?.toISOString(),
              })
            }
            placeholder="Start Date"
          />
          -
          <DateInput
            value={
              !isDateEmpty(data.datesOfEmployment?.value?.endDate)
                ? new Date(data.datesOfEmployment?.value?.endDate)
                : undefined
            }
            onValueChange={(val) =>
              doSetData("datesOfEmployment", {
                ...data.datesOfEmployment?.value,
                endDate: val?.toISOString(),
              })
            }
            placeholder="End Date"
          />
        </div>
      </Field>

      <Field id="companyWebsite" label="What's the company website?">
        <div className="flex flex-col gap-2">
          <Input
            type="url"
            placeholder="https://lighthousehq.com"
            value={data.companyWebsite?.value as string}
            onValueChange={(val) => doSetData("companyWebsite", val)}
          />
          {data.companyWebsite?.value != null &&
            data.companyWebsite?.value.trim().length > 0 &&
            !data.companyWebsite?.value.startsWith("https://") && (
              <div className="text-xs text-negative">
                Link format is incorrect, try pasting the URL.
              </div>
            )}
        </div>
      </Field>

      <Field
        id="keyProjectsAndResponsibilities"
        label="List 3 important projects you led or key responsibilities you had at this company"
      >
        <div className="flex flex-col gap-2">
          <Input
            type="text"
            placeholder="Key Responsibility"
            value={keyResponsibilities[0]}
            onValueChange={(val) => setKeyResponsibility(0, val)}
          />
          <Input
            type="text"
            placeholder="Key Responsibility"
            value={keyResponsibilities[1]}
            onValueChange={(val) => setKeyResponsibility(1, val)}
          />
          <Input
            type="text"
            placeholder="Key Responsibility"
            value={keyResponsibilities[2]}
            onValueChange={(val) => setKeyResponsibility(2, val)}
          />
        </div>
      </Field>

      <Field
        id="supportingDocuments"
        label="Upload any documents you authored, contributed to, or projects you were responsible for"
        description="All these documents will be securely stored, and only used for your visa purposes. The more you share, the stronger and more detailed we can make your case."
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="If you have any additional comments, that you think will be useful for us working on this criteria, please add them here."
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="critical-role"
          name="critical role"
          description="We have chosen this role based on what we know about you, if you think that another role is important for the process you can add it and we will verify it."
        />
      )}
    </OnboardingForm>
  );
};
