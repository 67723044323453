import { cn } from "@/lib/cn";
import { useResizeObserver } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";
import { CheckCircledIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { useEffect, useRef, useState } from "react";
import { AddNextCriteriaModal } from "./addNextCriteriaModal";

export type TimelineStep = {
  id: string;
  name: string;
  completed: boolean;
  highlighted: boolean;
};

const calculateWidths = (
  startRef: React.RefObject<HTMLDivElement | null>,
  endRef: React.RefObject<HTMLDivElement | null>,
  selectedStepRef: React.RefObject<HTMLDivElement | null>,
  allSteps: TimelineStep[],
  currentStepId: string
): {
  blackTimelineWidth: number;
  grayTimelineWidth: number;
} => {
  if (allSteps.length === 0)
    return { blackTimelineWidth: 0, grayTimelineWidth: 0 };

  if (currentStepId === allSteps[allSteps.length - 1].id) {
    return {
      blackTimelineWidth:
        (selectedStepRef.current?.offsetLeft ?? 0) -
        (startRef.current?.offsetLeft ?? 0),
      grayTimelineWidth: 0,
    };
  }

  if (currentStepId === allSteps[0].id) {
    return {
      blackTimelineWidth: 0,
      grayTimelineWidth:
        (endRef.current?.offsetLeft ?? 0) - (startRef.current?.offsetLeft ?? 0),
    };
  }

  return {
    blackTimelineWidth:
      (selectedStepRef.current?.offsetLeft ?? 0) -
      (startRef.current?.offsetLeft ?? 0),
    grayTimelineWidth:
      (endRef.current?.offsetLeft ?? 0) - (startRef.current?.offsetLeft ?? 0),
  };
};

const getSteps = (allSteps: TimelineStep[], allowCreate?: boolean) => {
  const steps = [...allSteps];

  if (allowCreate) {
    steps.push({
      id: "create-node",
      name: "Add a criteria",
      highlighted: false,
      completed: false,
    });
  }

  return steps;
};

export const Timeline = (props: {
  allSteps: TimelineStep[];
  currentStepId: string;
  onStepChange?: (step: TimelineStep) => void;
  allowCreate?: boolean;
  onClickCreateNode?: () => void;
}) => {
  const {
    allSteps,
    currentStepId,
    onStepChange,
    allowCreate = false,
    onClickCreateNode,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const startRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const selectedStepRef = useRef<HTMLDivElement>(null);

  const [blackTimelineWidth, setBlackTimelineWidth] = useState(0);
  const [grayTimelineWidth, setGrayTimelineWidth] = useState(0);

  const steps = getSteps(allSteps, allowCreate);

  useEffect(() => {
    const widths = calculateWidths(
      startRef,
      endRef,
      selectedStepRef,
      steps,
      currentStepId
    );
    setBlackTimelineWidth(widths.blackTimelineWidth);
    setGrayTimelineWidth(widths.grayTimelineWidth);
  }, [steps, currentStepId]);

  useResizeObserver(containerRef);

  useEffect(() => {
    if (selectedStepRef.current == null) return;
    if (allSteps.length === 0) return;
    if (currentStepId === allSteps[0].id) return;

    selectedStepRef.current.scrollIntoView({
      behavior: "instant",
      block: "nearest",
      inline: "start",
    });
  }, [currentStepId]);

  return (
    <div
      className="flex flex-col gap-1 w-full overflow-scroll pb-5 relative"
      ref={containerRef}
    >
      <div className="flex flex-row gap-3 items-center w-full">
        {steps.map((step, index) => (
          <div
            className="flex flex-col gap-2"
            ref={index === 0 ? startRef : null}
            key={`parent-${index}`}
          >
            <div
              key={index}
              ref={
                step.id === currentStepId
                  ? selectedStepRef
                  : step.id === steps[steps.length - 1].id
                    ? endRef
                    : null
              }
              onClick={() => {
                if (step.id === "create-node") {
                  onClickCreateNode?.();
                  return;
                }
                onStepChange?.(step);
              }}
              className={cn(
                "rounded-full border-grey-400 w-3 h-3 bg-grey-700 z-20 border border-dashed flex items-center justify-center",
                step.id === currentStepId && "border-grey-200 border-solid",
                step.completed && "bg-grey-500 border-none",
                index === 0 && "translate-x-[5px]",
                "transition-background-color ease-in-out duration-500",
                "transition-border ease-in-out duration-500",
                step.id === "create-node" && "border-none bg-transparent"
              )}
            >
              {step.completed && (
                <CheckCircledIcon
                  className="w-[9px] h-[9px] bg-grey-500 rounded-full"
                  stroke="#000"
                  strokeWidth={"0.25px"}
                />
              )}
              {step.id === "create-node" && (
                <PlusCircledIcon className="w-[12px] h-[12px] text-blue" />
              )}
            </div>
            <button
              className={cn(
                "px-2 py-1 text-grey-200 text-nowrap text-xs",
                step.id === currentStepId &&
                  "px-2 py-1 rounded-xl bg-white text-grey-200",
                step.id === "create-node" && "text-blue px-0 pr-1"
              )}
              onClick={() => {
                if (step.id === "create-node") {
                  onClickCreateNode?.();
                  return;
                }
                onStepChange?.(step);
              }}
            >
              {step.name}
              {step.highlighted && <span className="text-blue">*</span>}
            </button>
          </div>
        ))}
      </div>

      {steps.length > 0 && (
        <>
          <div
            style={{
              position: "absolute",
              top: `${(selectedStepRef.current?.offsetTop ?? 0) + (selectedStepRef.current?.offsetHeight ?? 0) / 2 - 1}px`,
              left: `${(startRef.current?.offsetLeft ?? 0) + 1}px`,
              width: `${Math.max(blackTimelineWidth, 5)}px`,
              backgroundColor: "black",
              height: "1px",
              zIndex: 10,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: `${(selectedStepRef.current?.offsetTop ?? 0) + (selectedStepRef.current?.offsetHeight ?? 0) / 2 - 1}px`,
              left: `${(startRef.current?.offsetLeft ?? 0) + 1}px`,
              width: `${grayTimelineWidth}px`,
              backgroundColor: "black",
              height: "1px",
              zIndex: 10,
              opacity: 0.16,
            }}
          />
        </>
      )}
    </div>
  );
};

export const OnboardingGroupTimeline = (props: { allowCreate?: boolean }) => {
  const { allowCreate } = props;
  const { groupNodes, switchNode, currentOnboardingNodeId, onboardingId } =
    useOnboarding();

  const [createNodeModalOpen, setCreateNodeModalOpen] = useState(false);

  const allSteps = groupNodes.map((node) => {
    const formatType = node.type
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");

    const fullName =
      node.name.toLowerCase() != formatType.toLowerCase() &&
      node.name.trim().length > 0
        ? formatType + " - " + node.name
        : node.name.trim().length > 0
          ? node.name
          : formatType;

    return {
      id: node.id.toString(),
      name: fullName,
      completed:
        node.internalStatus === "submitted_for_review" ||
        node.internalStatus === "approved",
      highlighted: node.customerCreated,
    };
  });

  return (
    <>
      <AddNextCriteriaModal
        open={createNodeModalOpen}
        setOpen={setCreateNodeModalOpen}
        onboardingId={onboardingId}
      />
      <Timeline
        allSteps={allSteps}
        currentStepId={currentOnboardingNodeId?.toString() ?? allSteps[0].id}
        onStepChange={(step) => {
          switchNode(parseInt(step.id));
        }}
        allowCreate={allowCreate}
        onClickCreateNode={() => setCreateNodeModalOpen(true)}
      />
    </>
  );
};
