import { cn } from "@/lib/cn";
import { Command } from "cmdk";
import { useEffect, useRef, useState } from "react";
import { PopoverTrigger, Popover, PopoverContent } from "./popover";
import {
  getCountries,
  getCountryCallingCode,
  isSupportedCountry,
  type Country,
} from "react-phone-number-input";
import { default as PhoneInput } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";

import { ButtonText, InputText, LabelText } from "./typography";
import { CalendarIcon } from "./icons/calendar";
import { DateTime } from "luxon";
import { ChevronDownIcon } from "@radix-ui/themes";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { TrashIcon } from "./icons/trash";
import { Select } from "./select";

export const Input = (props: {
  type?: "text" | "number" | "tel" | "email" | "url";
  value?: string;
  onValueChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  autoComplete?: string;
  min?: number;
}) => (
  <input
    type={props.type ?? "text"}
    className={cn(
      "flex flex-col items-center rounded-md resize-none pl-3 pr-2 py-2 w-full",
      "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%] placeholder:font-normal",
      "text-sm leading-[140%] font-normal",
      "disabled:bg-grey-700 disabled:text-grey-500 disabled:placeholder:text-grey-500",
      "shadow-border focus:outline-none focus:shadow-border-active",
      "transition-all duration-200",
      props.className
    )}
    defaultValue={props.value ?? ""}
    onChange={(e) => props.onValueChange?.(e.target.value)}
    placeholder={props.placeholder}
    disabled={props.disabled}
    autoComplete={props.autoComplete}
    required
    min={props.min}
    pattern={props.type === "url" ? "https://.*" : undefined}
  />
);

export const TextArea = (props: {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  charLimit?: number;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [props.value]);

  return (
    <div className="flex flex-col w-full">
      <textarea
        className={cn(
          "flex flex-col items-center rounded-md shadow-border resize-none pl-3 pr-1 py-2",
          "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%] placeholder:font-normal",
          "text-sm leading-[140%] font-normal",
          "focus:shadow-border-active focus:outline-none",
          "disabled:bg-grey-700 disabled:text-grey-500 disabled:placeholder:text-grey-700",
          "transition-all duration-300",
          props.className
        )}
        ref={textAreaRef}
        value={props.value}
        onChange={(e) => {
          props.onChange?.(e.target.value);
        }}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        maxLength={props.charLimit}
        onKeyDown={props.onKeyDown}
      />
      {props.charLimit != null && (
        <div className="text-xs text-grey-400 pt-1.5 ml-auto">
          {props.value?.length ?? 0} / {props.charLimit}
        </div>
      )}
    </div>
  );
};

export const CountryIcon = (props: { country: string }) => {
  const [hasError, setHasError] = useState(false);

  return (
    <div className="w-[28px] h-[28px] flex items-center justify-center rounded-full">
      <img
        src={
          !hasError
            ? "https://hatscripts.github.io/circle-flags/flags/{XX}.svg".replace(
                "{XX}",
                props.country.toLowerCase()
              )
            : "https://avatar.vercel.sh/{XX}".replace(
                "{XX}",
                props.country.toLowerCase()
              )
        }
        width="12px"
        role={props.country ? undefined : "presentation"}
        onError={() => setHasError(true)}
      />
    </div>
  );
};

const getCallingCode = (countryCode: string): string | undefined => {
  try {
    return getCountryCallingCode(countryCode as Country);
  } catch (e) {
    return undefined;
  }
};

const CountrySelect = (props: {
  onChange: (countryCode?: string) => void;
  value?: string;
  countryCallingCodeValue?: string;
  allowedCountryCodes?: string[];
}) => {
  const { onChange, value, allowedCountryCodes, countryCallingCodeValue } =
    props;

  const [open, setOpen] = useState(false);

  const allCountryCodes =
    allowedCountryCodes == null
      ? getCountries()
      : getCountries().filter((x) => allowedCountryCodes.includes(x));

  return (
    <div className="flex flex-row gap-1">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div className="py-1 pl-1 pr-3 shadow-border rounded-md bg-white min-w-[56px] min-h-8">
            <div className="flex flex-row items-center gap-1">
              <div className="flex flex-row items-center justify-center text-xs text-grey-300 font-normal">
                {value != null && countryCallingCodeValue != null && (
                  <>
                    <CountryIcon country={value} /> +{countryCallingCodeValue}
                  </>
                )}
              </div>
            </div>
          </div>
        </PopoverTrigger>

        <PopoverContent className={cn("z-10 p-0 shadow-border")}>
          <Command>
            <Command.Input
              className={cn(
                "flex flex-row w-full items-center rounded-t-lg resize-none px-2 py-2",
                "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%] placeholder:font-normal",
                "text-sm leading-[140%] font-normal",
                "focus:outline-none border-b border-b-grey-700"
              )}
              placeholder="Search"
            />
            <Command.List className="max-h-[200px] overflow-scroll">
              {allCountryCodes.map((countryCode) => {
                const callCode = getCallingCode(countryCode as Country);
                return (
                  <Command.Item
                    key={countryCode}
                    value={countryCode}
                    onSelect={() => {
                      onChange(countryCode);
                      setOpen(false);
                    }}
                    keywords={[
                      countryCode,
                      callCode != null ? `+${callCode}` : "",
                      en[countryCode as Country],
                    ]}
                    className="px-2 py-1 flex flex-row gap-1 items-center rounded-md hover:bg-grey-700 hover:cursor-pointer items-center"
                  >
                    {isSupportedCountry(countryCode as Country) && (
                      <CountryIcon country={countryCode} />
                    )}

                    <ButtonText className="font-normal w-full items-center">
                      <div className="flex flex-row gap-2">
                        <span className="text-grey-500">
                          {callCode != null && <>+{callCode}</>}
                        </span>

                        {en[countryCode as Country]}
                      </div>
                    </ButtonText>
                  </Command.Item>
                );
              })}
            </Command.List>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export type PhoneNumber = {
  country?: string;
  callingCode?: string;
  phoneNumber?: string;
};

export const PhoneNumberInput = (props: {
  value?: PhoneNumber;
  onValueChange: (value: PhoneNumber) => void;
  placeholder?: string;
  disabled?: boolean;
  allowedCountryCodes?: string[];
  className?: string;
}) => {
  const {
    value,
    onValueChange,
    placeholder,
    disabled,
    allowedCountryCodes,
    className,
  } = props;

  return (
    <div className={cn("flex flex-row gap-1 w-full", className)}>
      <CountrySelect
        onChange={(country) => {
          const callingCode = getCallingCode(country as Country);
          onValueChange({
            ...value,
            country: country,
            callingCode: callingCode,
          });
        }}
        value={value?.country}
        countryCallingCodeValue={value?.callingCode}
        allowedCountryCodes={allowedCountryCodes}
      />

      <PhoneInput
        value={value?.phoneNumber}
        disabled={disabled}
        onChange={(phone) => {
          onValueChange({
            ...value,
            phoneNumber: phone,
          });
        }}
        placeholder={placeholder}
        className={cn(
          "flex flex-row items-center gap-1 rounded-md shadow-border resize-none px-3 py-2 w-full",
          "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%] placeholder:font-normal",
          "text-sm leading-[140%] font-normal",
          "focus:outline-none focus:shadow-border-active",
          "disabled:bg-grey-600 disabled:text-grey-500 disabled:placeholder:text-grey-700",
          "transition-all duration-300",
          props.className
        )}
        country={value?.country as Country}
      />
    </div>
  );
};

const getCountryCodeByName = (name: string) => {
  for (const key in en) {
    if (en[key as keyof typeof en] === name) return key;
  }
  return;
};

export const CountryAddressInput = (props: {
  value?: string;
  onValueChange: (value?: string) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}) => {
  const { value, onValueChange, disabled, className } = props;

  const [open, setOpen] = useState(false);

  const allCountryCodes = getCountries();

  const buttonRef = useRef<HTMLDivElement>(null);
  const [buttonWidth, setButtonWidth] = useState(0);

  const countryCode = value != null ? getCountryCodeByName(value) : undefined;

  useEffect(() => {
    const btnRef = buttonRef.current;
    if (btnRef == null) return;

    setButtonWidth(btnRef.offsetWidth);

    const handleResize = () => {
      setButtonWidth(btnRef.offsetWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={cn("flex flex-row gap-1 w-full", className)}
      ref={buttonRef}
    >
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            className="py-1 pl-1 pr-3 shadow-border rounded-md bg-white w-full focus:outline-none focus:ring-0 focus:shadow-border-active"
            disabled={disabled}
          >
            <div className="flex flex-row items-center gap-1 max-h-[36px]">
              {value != null ? (
                <div className="flex flex-row items-center justify-center font-normal">
                  {countryCode != null && <CountryIcon country={countryCode} />}
                  {value}
                </div>
              ) : (
                <InputText className="text-grey-500 pl-2 py-1 font-normal">
                  Select a country
                </InputText>
              )}
              <ChevronDownIcon className="ml-auto" />
            </div>
          </button>
        </PopoverTrigger>

        <PopoverContent
          className={cn("z-20 p-0 shadow-border")}
          style={{
            width: buttonWidth,
          }}
        >
          <Command>
            <Command.Input
              className={cn(
                "flex flex-row w-full items-center rounded-t-lg resize-none px-2 py-2",
                "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%]",
                "text-sm leading-[140%] font-normal",
                "focus:outline-none border-b border-b-grey-700"
              )}
              placeholder="Search"
            />
            <Command.List
              className={cn("max-h-[200px] overflow-scroll w-full")}
            >
              {allCountryCodes.map((countryCode) => {
                const countryName = en[countryCode as Country];
                return (
                  <Command.Item
                    key={countryName}
                    value={countryName}
                    onSelect={(currentValue) => {
                      if (disabled) return;
                      onValueChange(currentValue);
                      setOpen(false);
                    }}
                    className="px-2 py-1 flex flex-row gap-1 items-center rounded-md hover:bg-grey-700 data-[selected=true]:bg-grey-700 hover:cursor-pointer items-center w-full first:rounded-t-none last:rounded-b-none"
                  >
                    {isSupportedCountry(countryCode as Country) && (
                      <CountryIcon country={countryCode} />
                    )}

                    <ButtonText className="font-normal w-full items-center">
                      <div className="flex flex-row gap-2">{countryName}</div>
                    </ButtonText>
                  </Command.Item>
                );
              })}
            </Command.List>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const DateInput = (props: {
  value?: Date;
  onValueChange: (value?: Date) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const doClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (inputRef.current == null) return;
    if (props.disabled) return;
    inputRef.current.showPicker();
    e.stopPropagation();
  };

  const slicedValue =
    props.value != null ? props.value.toISOString().slice(0, 10) : "";

  return (
    <div className="relative w-full">
      <button
        className={cn(
          "w-full flex flex-row items-center rounded-md shadow-border pr-1 pl-3 py-1 justify-between bg-white",
          props.disabled && "bg-grey-700 text-grey-500",
          props.className
        )}
        onClick={doClick}
      >
        {props.value != null && (
          <InputText className="text-grey-100 text-sm leading-[140%] font-normal">
            {DateTime.fromISO(slicedValue).toFormat("MMMM dd, yyyy", {
              locale: "en-US",
            })}
          </InputText>
        )}

        {props.value == null && (
          <InputText className="text-grey-500 text-sm leading-[140%] font-normal">
            {props.placeholder ?? "Select a date"}
          </InputText>
        )}

        <div className="shadow-border h-[28px] w-[28px] flex items-center justify-center rounded-[4px] bg-grey-800">
          <CalendarIcon />
        </div>
      </button>
      <input
        ref={inputRef}
        type="date"
        value={props.value != null ? slicedValue : ""}
        onChange={(e) => props.onValueChange(new Date(e.target.value))}
        className="sr-only"
        disabled={props.disabled}
      />
    </div>
  );
};

export const Checkbox = (props: {
  checked: boolean;
  onCheckedChange?: (value: boolean) => void;
  className?: string;
  id?: string;
  label?: string;
  labelClassName?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={cn("flex flex-row items-center", props.className)}>
      <CheckboxPrimitive.Root
        className={cn(
          "w-3.5 h-3.5 rounded-[2px] border border-grey-200 flex items-center justify-center",
          props.checked && "bg-grey-150",
          "transition-all duration-150 ease-in-out",
          "disabled:pointer-events-none disabled:opacity-50"
        )}
        disabled={props.disabled}
        checked={props.checked}
        id={props.id ?? "c1"}
        onCheckedChange={props.onCheckedChange}
      >
        <CheckboxPrimitive.Indicator className="appearance-none text-grey-800">
          <CheckIcon width={10} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>

      {props.label != null && props.label.trim().length > 0 && (
        <LabelText
          htmlFor={props.id ?? "c1"}
          className={cn(
            "text-grey-300 pt-0.5 pr-2 pb-[1px] pl-2 gap-1.5",
            props.labelClassName
          )}
        >
          {props.label}
        </LabelText>
      )}
    </div>
  );
};

export const ListTextInput = (props: {
  value?: string[];
  onValueChange: (value: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const { value, onValueChange } = props;

  return (
    <div className="flex flex-col gap-2 mr-12 mt-2">
      <div className="flex flex-col gap-2 w-full">
        {(value == null || value.length === 0 ? [undefined] : value).map(
          (val, index) => (
            <div
              key={index}
              className="flex flex-row w-full group bg-white rounded-md has-[:focus]:shadow-border-active shadow-border transition-all duration-150"
            >
              <Input
                placeholder={props.placeholder}
                type="text"
                value={val}
                onValueChange={(newVal) => {
                  const newValArray = [...(value ?? [""])];
                  newValArray[index] = newVal;
                  onValueChange(newValArray);
                }}
                disabled={props.disabled}
                className="w-full shadow-none focus:shadow-none transition-none"
              />
              <button
                className={cn(
                  "hidden opacity-0 group-hover:block group-hover:opacity-100 pr-2 transition-all duration-500",
                  index === 0 &&
                    (value ?? [undefined]).length === 1 &&
                    "group-hover:hidden"
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  const newValArray = [...(value ?? [""])];
                  newValArray.splice(index, 1);
                  onValueChange(newValArray);
                }}
              >
                <TrashIcon className="fill-grey-100" />
              </button>
            </div>
          )
        )}
      </div>

      <button
        className="flex flex-row gap-[6px] py-1.5 items-center w-fit"
        onClick={() => {
          const newValArray = [...(value ?? [""])];
          newValArray.push("");
          onValueChange(newValArray);
        }}
        disabled={props.disabled}
      >
        <PlusCircledIcon className="text-blue" width={12} height={12} />
        <InputText className="text-blue text-[12px]">Add next link</InputText>
      </button>
    </div>
  );
};

const usStates = [
  { name: "ALABAMA", abbreviation: "AL" },
  { name: "ALASKA", abbreviation: "AK" },
  { name: "AMERICAN SAMOA", abbreviation: "AS" },
  { name: "ARIZONA", abbreviation: "AZ" },
  { name: "ARKANSAS", abbreviation: "AR" },
  { name: "CALIFORNIA", abbreviation: "CA" },
  { name: "COLORADO", abbreviation: "CO" },
  { name: "CONNECTICUT", abbreviation: "CT" },
  { name: "DELAWARE", abbreviation: "DE" },
  { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
  { name: "FEDERATED STATES OF MICRONESIA", abbreviation: "FM" },
  { name: "FLORIDA", abbreviation: "FL" },
  { name: "GEORGIA", abbreviation: "GA" },
  { name: "GUAM", abbreviation: "GU" },
  { name: "HAWAII", abbreviation: "HI" },
  { name: "IDAHO", abbreviation: "ID" },
  { name: "ILLINOIS", abbreviation: "IL" },
  { name: "INDIANA", abbreviation: "IN" },
  { name: "IOWA", abbreviation: "IA" },
  { name: "KANSAS", abbreviation: "KS" },
  { name: "KENTUCKY", abbreviation: "KY" },
  { name: "LOUISIANA", abbreviation: "LA" },
  { name: "MAINE", abbreviation: "ME" },
  { name: "MARSHALL ISLANDS", abbreviation: "MH" },
  { name: "MARYLAND", abbreviation: "MD" },
  { name: "MASSACHUSETTS", abbreviation: "MA" },
  { name: "MICHIGAN", abbreviation: "MI" },
  { name: "MINNESOTA", abbreviation: "MN" },
  { name: "MISSISSIPPI", abbreviation: "MS" },
  { name: "MISSOURI", abbreviation: "MO" },
  { name: "MONTANA", abbreviation: "MT" },
  { name: "NEBRASKA", abbreviation: "NE" },
  { name: "NEVADA", abbreviation: "NV" },
  { name: "NEW HAMPSHIRE", abbreviation: "NH" },
  { name: "NEW JERSEY", abbreviation: "NJ" },
  { name: "NEW MEXICO", abbreviation: "NM" },
  { name: "NEW YORK", abbreviation: "NY" },
  { name: "NORTH CAROLINA", abbreviation: "NC" },
  { name: "NORTH DAKOTA", abbreviation: "ND" },
  { name: "NORTHERN MARIANA ISLANDS", abbreviation: "MP" },
  { name: "OHIO", abbreviation: "OH" },
  { name: "OKLAHOMA", abbreviation: "OK" },
  { name: "OREGON", abbreviation: "OR" },
  { name: "PALAU", abbreviation: "PW" },
  { name: "PENNSYLVANIA", abbreviation: "PA" },
  { name: "PUERTO RICO", abbreviation: "PR" },
  { name: "RHODE ISLAND", abbreviation: "RI" },
  { name: "SOUTH CAROLINA", abbreviation: "SC" },
  { name: "SOUTH DAKOTA", abbreviation: "SD" },
  { name: "TENNESSEE", abbreviation: "TN" },
  { name: "TEXAS", abbreviation: "TX" },
  { name: "UTAH", abbreviation: "UT" },
  { name: "VERMONT", abbreviation: "VT" },
  { name: "VIRGIN ISLANDS", abbreviation: "VI" },
  { name: "VIRGINIA", abbreviation: "VA" },
  { name: "WASHINGTON", abbreviation: "WA" },
  { name: "WEST VIRGINIA", abbreviation: "WV" },
  { name: "WISCONSIN", abbreviation: "WI" },
  { name: "WYOMING", abbreviation: "WY" },
];

export const USStateSelect = (props: {
  value?: string;
  onValueChange: (value?: string) => void;
}) => {
  const { value, onValueChange } = props;

  return (
    <Select
      value={value}
      onValueChange={onValueChange}
      placeholder="Select a state"
      options={usStates.map((state) => ({
        value: state.abbreviation,
        label: state.abbreviation,
      }))}
      className="bg-white rounded-lg"
    />
  );
};

export const IncomeInput = (props: {
  value?: string;
  onValueChange?: (value: string) => void;
}) => {
  return (
    <div className="flex flex-row w-full group bg-white rounded-md has-[:focus]:shadow-border-active shadow-border transition-all duration-150">
      <div className="bg-grey-700 flex flex-row items-center gap-0.25 rounded-l-md pl-1 pr-3 py-1 text-grey-300 text-xs">
        <CountryIcon country="US" />
        USD
      </div>
      <input
        value={props.value ?? ""}
        onChange={(e) => props.onValueChange?.(e.target.value)}
        type="text"
        placeholder="0.0"
        className={cn(
          "w-full rounded-r-md px-3 py-2 bg-white",
          "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%] placeholder:font-normal",
          "text-sm leading-[140%] font-normal",
          "disabled:bg-grey-700 disabled:text-grey-500 disabled:placeholder:text-grey-500",
          "focus:outline-none"
        )}
      />
    </div>
  );
};
