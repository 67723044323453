import {
  EditorContextProvider,
  useEditorContext,
} from "@/providers/editorProvider";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { TextEditorComments } from "@/components/textEditorComments";
import { TextEditor } from "@/components/textEditor";

import { useCallback, useEffect, useState } from "react";
import { Spinner } from "@radix-ui/themes";
import { Mark } from "@/components/mark";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Modal } from "@/components/modal";
import { Button } from "@/components/button";
import { FaRegCircleCheck } from "react-icons/fa6";
import { customerApi } from "@lighthouse/api";
import {
  ChangesSuggestedPopup,
  CommentHintPopup,
  ConfirmApproveModal,
  FailiureModal,
  hasUserSubmittedComment,
  InformationModal,
} from "@/routes/_portal/letter/review/$letterActionId";
import { ExternalLandingPage } from "@/components/externalLandingPage";
import { ExternalTokenExpiredPage } from "@/components/externalTokenExpiredPage";
import { LoadingOverlay } from "@/components/loadingOverlay";
import { useQuery } from "@tanstack/react-query";
import { LetterActionNotFoundPage } from "@/components/letterActionNotFoundPage";

export type LetterActionType = {
  id: number;
  type: string;
  letterId: number;
  externalEmail: string;
  externalName: string;
  status: string;
  beneficiaryName: string;
  additionalNotes?: string;
};

const SuccessModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  signatureLetterAction?: {
    letterActionId: number;
    token: string;
  };
}) => {
  const { open, setOpen, signatureLetterAction } = props;

  const nav = useNavigate();

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Letter Approved!"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        {signatureLetterAction == null && (
          <p className="text-sm text-grey-300">
            This letter has been successfully approved. Other reviews are still
            in progress. You will be notified when your signature is needed. You
            can close this window.
          </p>
        )}

        {signatureLetterAction != null && (
          <p className="text-sm text-grey-300">
            This letter has been successfully approved, and is ready for your
            signature. Click the button below to sign the letter.
          </p>
        )}
      </div>

      <div className="flex flex-row justify-end">
        {signatureLetterAction == null && (
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() => setOpen(false)}
          >
            Got it
          </Button>
        )}

        {signatureLetterAction != null && (
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() =>
              nav({
                to: "/external/letter/sign/$letterActionId",
                params: {
                  letterActionId:
                    signatureLetterAction.letterActionId.toString(),
                },
                search: {
                  token: signatureLetterAction.token,
                  reviewed: true,
                },
              })
            }
          >
            Sign Letter
          </Button>
        )}
      </div>
    </Modal>
  );
};

const SubmitReviewButton = (props: {
  letterActionId: number;
  hasSuggestedChanges: boolean;
  setStatus: (value: string) => void;
  status: string;
}) => {
  const { letterActionId, hasSuggestedChanges, setStatus, status } = props;

  const { token } = Route.useSearch();

  const [loading, setLoading] = useState(false);
  const [confirmApprovalModalOpen, setConfirmApprovalModalOpen] =
    useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [signatureLetterAction, setSignatureLetterAction] = useState<{
    letterActionId: number;
    token: string;
  }>();

  const { provider, editor } = useEditorContext();

  const approve = async () => {
    if (loading || provider?.document == null || editor == null) return;
    setLoading(true);

    // const comments = provider.document.getArray(
    //   "comments"
    // ) as Y.Array<CommentYMap>;

    // for (let i = 0; i < provider.document.getArray("comments").length; i++) {
    //   const comment = comments.get(i);

    //   if (comment.get("userId") === userId) {
    //     comment.set("resolved", true);
    //     editor.commands.setCommentHighlightResolvedById(
    //       comment.get("id") as string,
    //       true
    //     );
    //   }
    // }

    const res = await customerApi.submitLetterAction({
      letterActionId: letterActionId,
      token: token,
      action: "approve",
    });

    if (!res.success) {
      setLoading(false);
      setFailureModalOpen(true);
      return;
    }

    const followUpRes = await customerApi.getFollowupAction({
      letterActionId: letterActionId,
      token: token,
    });

    setLoading(false);

    if (
      followUpRes.data?.letterActionId != null &&
      followUpRes.data.token != null
    )
      setSignatureLetterAction(followUpRes.data);

    setSuccessModalOpen(true);
    setStatus("approved");
  };

  return (
    <div>
      <SuccessModal
        open={successModalOpen}
        setOpen={setSuccessModalOpen}
        signatureLetterAction={signatureLetterAction}
      />

      <LoadingOverlay isLoading={loading} />

      <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />

      <ConfirmApproveModal
        open={confirmApprovalModalOpen}
        setOpen={setConfirmApprovalModalOpen}
        onApprove={approve}
      />

      {status === "approved" && (
        <Button
          variant="primary"
          disabled
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Letter Approved
        </Button>
      )}

      {status !== "approved" && (
        <Button
          onClick={
            hasSuggestedChanges
              ? () => setConfirmApprovalModalOpen(true)
              : approve
          }
          variant="primary"
          disabled={loading}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Approve Letter
        </Button>
      )}
    </div>
  );
};

const EditorRenderer = (props: {
  setInformationModalOpen: (value: boolean) => void;
  letterActionId: number;
  letterActionStatus: string;
  letterId: number;
  initialStatus: string;
  additionalNotes?: string;
}) => {
  const {
    setInformationModalOpen,
    letterActionId,
    letterId,
    letterActionStatus,
    initialStatus,
    additionalNotes,
  } = props;

  const { token } = Route.useSearch();

  const [status, setStatus] = useState<string>(initialStatus);

  const { provider, userId } = useEditorContext();

  const [hasSuggestedChanges, setHasSuggestedChanges] = useState<boolean>();

  const checkComments = useCallback(() => {
    const hasSubmittedComment = hasUserSubmittedComment(provider, userId);

    setHasSuggestedChanges(hasSubmittedComment);
  }, [provider, userId]);

  useEffect(() => {
    if (provider?.document == null) return;

    checkComments();

    provider.document.getArray("comments")?.observeDeep(checkComments);

    return () =>
      provider.document.getArray("comments")?.observeDeep(checkComments);
  }, [provider, checkComments]);

  const onCommentAdded = async () => {
    if (letterActionStatus === "sent") {
      const res = await customerApi.submitLetterAction({
        letterActionId: letterActionId,
        token: token,
        action: "request_changes",
      });

      if (!res.success) return;

      setStatus("changes-requested");
    }
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center overflow-hidden">
      {status === "changes-requested" && <ChangesSuggestedPopup hideButton />}
      {hasSuggestedChanges == false && status === "sent" && (
        <CommentHintPopup />
      )}
      <div className="w-full flex flex-row items-center justify-between px-6 h-20 mb-3 flex-shrink-0">
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center">
          <Mark />
          <h1 className="font-serif font-[600] text-base tracking-[-0.5%]">
            Lighthouse
          </h1>
        </div>

        <div className="flex flex-row items-center gap-3">
          <button
            onClick={() => setInformationModalOpen(true)}
            className="bg-grey-200 shadow-action-button h-8 w-8 rounded-full flex items-center justify-center"
          >
            <InfoCircledIcon color="white" width={20} />
          </button>

          {hasSuggestedChanges != null && (
            <SubmitReviewButton
              letterActionId={letterActionId}
              hasSuggestedChanges={hasSuggestedChanges}
              setStatus={setStatus}
              status={status}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row overflow-hidden">
        <div className="flex flex-col gap-4 overflow-hidden p-10 pb-0">
          {status === "approved" && (
            <div className="flex flex-row items-center gap-4 bg-grey-600 shadow-bubble p-5 w-[825px] rounded-lg text-sm text-grey-300">
              <div className="h-9 w-9 bg-[#D9D9D9] rounded-full flex items-center justify-center text-grey-300 text-lg">
                <FaRegCircleCheck />
              </div>

              <span>
                You have approved this letter. There is no need for you to take
                any action, we will inform you when your signature is needed.
              </span>
            </div>
          )}
          <div className="overflow-y-auto no-scrollbar pb-10">
            <TextEditor
              key={letterId + status}
              disabled={status === "approved"}
            />
          </div>
        </div>

        {status !== "approved" && (
          <div className="sticky top-0 overflow-y-auto no-scrollbar py-8">
            {additionalNotes != null && additionalNotes.trim().length > 0 && (
              <div className="w-[400px] flex flex-col gap-3 bg-grey-800 p-3 rounded-lg transition-all group/container ring-2 ring-grey-600 ring-inset mb-4">
                <p className="text-sm text-grey-200 font-medium">
                  Additional information:
                </p>

                <div
                  className="text-[13px] text-grey-300"
                  dangerouslySetInnerHTML={{
                    __html: additionalNotes,
                  }}
                ></div>
              </div>
            )}

            <TextEditorComments onCommentAdded={onCommentAdded} />
          </div>
        )}
      </div>
    </div>
  );
};

const LetterReviewEditor = (props: {
  data: LetterActionType;
  hideInfoModal: boolean;
}) => {
  const { data, hideInfoModal } = props;

  const [informationModalOpen, setInformationModalOpen] = useState(
    data.status === "sent"
  );

  return (
    <div
      className="relative w-full h-full"
      style={{
        background: "url('/backgrounds/letter-review.webp')",
        backgroundBlendMode: "normal",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {!hideInfoModal && (
        <InformationModal
          isBeneficiary={false}
          beneficiaryName={data.beneficiaryName}
          open={informationModalOpen}
          setOpen={setInformationModalOpen}
        />
      )}

      <div className="w-full h-full absolute bg-grey-800 bg-opacity-25 backdrop-blur-[2px]" />
      <EditorContextProvider
        letterId={data.letterId}
        userId={data.externalEmail}
        userName={data.externalName}
        commentUserIdWhitelist={[data.externalEmail]}
        mode="review"
        letterActionId={data.id}
      >
        <EditorRenderer
          letterActionId={data.id}
          letterId={data.letterId}
          letterActionStatus={data.status}
          additionalNotes={data.additionalNotes}
          setInformationModalOpen={setInformationModalOpen}
          initialStatus={data.status}
        />
      </EditorContextProvider>
    </div>
  );
};

const LetterReview = () => {
  const { letterActionId } = Route.useParams();
  const { token } = Route.useSearch();

  const [showLanding, setShowLanding] = useState(true);

  const { data, isPending, error } = useQuery({
    retry: 1,
    queryKey: ["externalLetterAction", letterActionId],
    queryFn: async () => {
      const res = await customerApi.getLetterAction({
        letterActionId: parseInt(letterActionId),
        token: token,
      });

      if (
        res.success &&
        res.data != null &&
        res.data.type === "review-approve"
      ) {
        return res.data as LetterActionType;
      }

      if (res.message === "403") {
        throw new Error("token expired");
      }

      throw new Error("not found");
    },
  });

  if (error?.message === "token expired")
    return (
      <ExternalTokenExpiredPage
        letterActionId={parseInt(letterActionId)}
        token={token}
      />
    );

  if (error != null) return <LetterActionNotFoundPage external />;

  if (isPending || data == null)
    return (
      <div className="flex flex-col items-center text-center h-screen w-screen justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="h-screen w-screen overflow-y-auto">
      {showLanding && (
        <ExternalLandingPage hide={() => setShowLanding(false)} />
      )}
      <LetterReviewEditor data={data} hideInfoModal={showLanding} />
    </div>
  );
};

type ReviewSearch = { token: string };

export const Route = createFileRoute("/external/letter/review/$letterActionId")(
  {
    component: () => <LetterReview />,
    validateSearch: (search: Record<string, unknown>): ReviewSearch => {
      return {
        token: (search.token as string) || "",
      };
    },
  }
);
