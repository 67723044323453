import { NodeViewWrapper, NodeViewProps } from "@tiptap/react";
import { FaSignature } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import SignatureCanvas, { SignatureRef } from "@uiw/react-signature";
import { Modal } from "@/components/modal";
import { Button } from "@/components/button";
import { customerApi } from "@lighthouse/api";

const SignatureModal = (props: {
  open: boolean;
  addSignature: (src: string) => void;
  setOpen: (value: boolean) => void;
}) => {
  const { open, setOpen, addSignature } = props;

  const signatureRef = useRef<SignatureRef>(null);

  const [isEmpty, setIsEmpty] = useState(true);

  const clear = () => {
    if (signatureRef.current == null) return;

    setIsEmpty(true);
    signatureRef.current.clear();
  };

  const submit = () => {
    if (isEmpty || signatureRef?.current?.svg == null) return;

    const svgelm = signatureRef.current.svg.cloneNode(true) as SVGSVGElement;
    const clientWidth = signatureRef.current.svg.clientWidth;
    const clientHeight = signatureRef.current.svg.clientHeight;
    svgelm.removeAttribute("style");
    svgelm.setAttribute("viewbox", `0 0 ${clientWidth} ${clientHeight}`);
    svgelm.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    const base64 = btoa(svgelm.outerHTML);
    addSignature(`data:image/svg+xml;base64,${base64}`);

    setOpen(false);
  };

  const onNewLineDrawn = (points: number[][]) => {
    if (points.length === 0) return;

    setIsEmpty(false);
  };

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Create Signature"
      fitContent
      contentClassName="w-[650px] max-w-[80%] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <div className="w-full bg-grey-800 rounded-lg">
          <SignatureCanvas
            width={500}
            height={170}
            ref={signatureRef}
            onPointer={onNewLineDrawn}
            style={
              {
                "--w-signature-background": "transparent",
              } as React.CSSProperties
            }
          />
        </div>

        <div className="flex flex-row justify-end gap-2">
          <Button disabled={isEmpty} variant="secondary" onClick={clear}>
            Clear
          </Button>
          <Button disabled={isEmpty} variant="primary" onClick={submit}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const SignatureBox = (props: NodeViewProps) => {
  const [signerName, setSignerName] = useState<string>();

  useEffect(() => {
    const getSignatureBoxDetails = async () => {
      try {
        const res = await customerApi.getLetterActionRecipientName({
          letterActionId: parseInt(props.node.attrs.letterActionId),
        });

        if (res.success && res.data != null) {
          setSignerName(res.data.name);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getSignatureBoxDetails();
  }, []);

  const [signatureModalOpen, setSignatureModalOpen] = useState(false);

  if (signerName == null) return;

  const addSignature = (src: string) => {
    if (src === "") return;

    props.extension.options.onSignatureAdded?.();

    props.updateAttributes({
      signed: true,
      src: src,
    });
  };

  const formattedSignerName =
    signerName[signerName.length - 1] == "s"
      ? signerName + "'"
      : signerName + "'s";

  if (
    !props.extension.options.disabled &&
    props.extension.options.letterActionId === props.node.attrs.letterActionId
  ) {
    if (props.node.attrs.signed) {
      return (
        <NodeViewWrapper>
          <div className="flex flex-col gap-1 w-[270px] my-3">
            <div className="w-[250px] h-[80px] flex items-center justify-center">
              <img
                src={props.node.attrs.src}
                width="250px"
                height="80px"
                className="block"
              />
            </div>

            <div className="w-full h-[1px] bg-grey-400" />
          </div>
        </NodeViewWrapper>
      );
    }

    return (
      <>
        <SignatureModal
          open={signatureModalOpen}
          setOpen={setSignatureModalOpen}
          addSignature={addSignature}
          key={JSON.stringify(signatureModalOpen)}
        />
        <NodeViewWrapper>
          <div className="flex flex-col gap-1 w-[270px] my-3">
            <button
              onClick={() => setSignatureModalOpen(true)}
              className="flex flex-row gap-2 h-[60px] w-full items-center justify-center rounded-lg bg-[#D8E5FF] text-blue text-sm"
            >
              <FaSignature className="text-lg" />
              <span>Your signature</span>
            </button>

            <div className="w-full h-[1px] bg-grey-400" />
          </div>
        </NodeViewWrapper>
      </>
    );
  }

  if (props.node.attrs.signed) {
    return (
      <NodeViewWrapper>
        <div className="w-[250px] h-[80px] my-3">
          <img
            src={props.node.attrs.src}
            width="250px"
            height="80px"
            className="block"
          />
        </div>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper>
      <div
        contentEditable={false}
        className="flex items-center justify-center w-[270px] min-h-[60px] px-8 py-2 border border-grey-500 border-dashed rounded-lg overflow-hidden my-2"
      >
        <div className="flex flex-1 items-center justify-center text-xs text-grey-400 text-center">
          <span>
            <span className="font-semibold">{formattedSignerName}</span>{" "}
            signature will appear here
          </span>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
