import { useCompany } from "@/providers/companyProvider";
import { Button } from "./button";
import { useState } from "react";
import { AboutIcon, CompanyInfoIcon } from "./icons/sidebar";
import { cn } from "@/lib/cn";
import { getCompletedFields } from "./sidebar";
import { useNavigate } from "@tanstack/react-router";
import { RequestNewApplicantButton } from "./requestNewApplicant";
import { CheckIcon } from "@radix-ui/react-icons";
import { Spinner } from "@radix-ui/themes";
import { ResultOf } from "gql.tada";
import { getCompanyWorkspace } from "@/lib/queries";
import { VisaClassBadge } from "./visaClassBadge";
import { ApplicantStatusBadge } from "./applicantStatusBadge";
import { Avatar } from "./avatar";

const OpenIcon = () => (
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.43301 5.75C4.24056 6.08333 3.75944 6.08333 3.56699 5.75L0.968912 1.25C0.776461 0.916666 1.01702 0.499999 1.40192 0.499999L6.59808 0.5C6.98298 0.5 7.22354 0.916667 7.03109 1.25L4.43301 5.75Z"
      fill="#2C2C2C"
      stroke="#2C2C2C"
    />
  </svg>
);

const ClosedIcon = () => (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 4.06699C6.58333 4.25944 6.58333 4.74056 6.25 4.93301L1.75 7.53109C1.41667 7.72354 1 7.48298 1 7.09808L1 1.90192C1 1.51702 1.41667 1.27646 1.75 1.46891L6.25 4.06699Z"
      fill="#2C2C2C"
      stroke="#2C2C2C"
    />
  </svg>
);

const DropdownRow = (props: {
  children: React.ReactNode;
  noBorder?: boolean;
  className?: string;
}) => {
  const { children, noBorder = false, className } = props;
  return (
    <div
      className={cn(
        "flex flex-row items-center py-4 text-sm text-grey-300",
        !noBorder && "border-b-[1px] border-grey-600",
        className
      )}
    >
      {children}
    </div>
  );
};

const Dropdown = (props: {
  icon: React.ReactNode;
  title: string;
  count?: number;
  children: React.ReactNode;
  defaultOpen?: boolean;
}) => {
  const { icon, title, children, count } = props;

  const [open, setOpen] = useState(props.defaultOpen ?? false);

  return (
    <div className={cn("w-full flex flex-col", open && "mb-2")}>
      <div className="flex flex-row items-center gap-3">
        <button onClick={() => setOpen((prev) => !prev)} className="w-2">
          {open ? <OpenIcon /> : <ClosedIcon />}
        </button>
        {icon}
        <span className="text-sm text-grey-100 font-medium">{title}</span>
        {count != null && (
          <span className="text-sm text-grey-400">{count}</span>
        )}
      </div>

      {open && <div className="flex flex-col pl-6 mt-2">{children}</div>}
    </div>
  );
};

const CaseDropdownRow = (props: {
  caseData: NonNullable<
    ResultOf<typeof getCompanyWorkspace>["getCompanyWorkspace"]["cases"][0]
  >;
  onClick?: () => void;
}) => {
  const { caseData, onClick } = props;

  return (
    <DropdownRow>
      <div className="grid grid-cols-4 w-full h-full items-center">
        <div className="text-grey-200 text-sm flex flex-row gap-2 items-center">
          <Avatar
            username={caseData.beneficiaryName}
            className="w-4 h-4 rounded-full"
          />
          {caseData.beneficiaryName}
        </div>
        <div>
          <VisaClassBadge visaClass={caseData.visaClass} />
        </div>
        <div>
          <ApplicantStatusBadge status={caseData.status} />
        </div>

        <Button variant="primary" className="ml-auto w-28" onClick={onClick}>
          Complete
        </Button>
      </div>
    </DropdownRow>
  );
};

const SignatureIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 12.144H14.8487"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 15.1668C1.75 15.1668 9.02484 3.07568 5.14497 3.07568C2.25821 3.07568 2.22735 15.2367 8.29937 7.62183C8.29937 7.62183 8.81009 10.1056 10.0142 10.1289C10.5003 10.1383 11.1005 9.73148 11.826 8.61746C11.826 8.61746 11.826 10.1289 14.8487 10.1289"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SignSupportingDocumentsActions = () => {
  const { data, fetching } = useCompany();

  const nav = useNavigate();

  if (fetching) return;

  const pendingCases =
    data?.getCompanyWorkspace.cases.filter(
      (caseData) => !["approved", "denied", "rfe"].includes(caseData.status)
    ) ?? [];

  if (pendingCases?.length === 0) return;

  const casesWithPendingLettersOrForms = pendingCases.filter((caseData) => {
    const anyPendingLetters = (
      caseData.companyOnboarding?.letterActions ?? []
    ).filter(
      (letterAction) =>
        letterAction?.status === "pending" || letterAction?.status === "sent"
    );

    const anyPendingForms = (
      caseData.companyOnboarding?.formActions ?? []
    ).filter((formAction) => formAction?.status === "sent");

    return anyPendingLetters.length > 0 || anyPendingForms.length > 0;
  });

  if (casesWithPendingLettersOrForms?.length === 0) return;

  return (
    <Dropdown
      icon={<SignatureIcon />}
      title="Sign supporting documents for applicants"
      defaultOpen
    >
      {casesWithPendingLettersOrForms.map((caseData) => (
        <CaseDropdownRow
          key={`sig-${caseData.id}`}
          caseData={caseData}
          onClick={() =>
            nav({
              to: "/applicants/$caseId",
              params: { caseId: caseData.id.toString() },
            })
          }
        />
      ))}
    </Dropdown>
  );
};

export const ReviewCaseActions = () => {
  const { data, fetching } = useCompany();

  const nav = useNavigate();

  if (fetching) return;

  const pendingCases =
    data?.getCompanyWorkspace.cases.filter(
      (caseData) =>
        caseData.companyOnboarding?.publishedCase != null &&
        caseData.companyOnboarding.publishedCase.length > 0 &&
        caseData.companyOnboarding.publishedCase[0]?.status === "pending"
    ) ?? [];

  if (pendingCases?.length === 0) return;

  return (
    <Dropdown
      icon={<SignatureIcon />}
      title="Review final visa application"
      defaultOpen
    >
      {pendingCases.map((caseData) => (
        <CaseDropdownRow
          key={`published-case-${caseData.id}`}
          caseData={caseData}
          onClick={() =>
            nav({
              to: "/applicants/$caseId",
              params: { caseId: caseData.id.toString() },
            })
          }
        />
      ))}
    </Dropdown>
  );
};

export const CompanyDetailsActions = () => {
  const { data, fetching } = useCompany();

  const nav = useNavigate();
  const completedFields = data != null ? getCompletedFields(data) : undefined;

  if (fetching) return <Spinner />;

  const entityDataComplete =
    completedFields?.entityData.completed === completedFields?.entityData.total;
  const filesComplete =
    completedFields?.files.completed === completedFields?.files.total;

  return (
    <Dropdown
      icon={<CompanyInfoIcon />}
      title="Complete company details"
      defaultOpen
    >
      <DropdownRow>
        Fill in general company information
        {entityDataComplete ? (
          <span className="px-2 py-1 ml-auto flex flex-row gap-1 items-center rounded-md bg-positive bg-opacity-10 text-positive text-xs w-28">
            Completed
            <CheckIcon className="w-4 h-4 text-positive ml-auto" />
          </span>
        ) : (
          <Button
            variant="primary"
            className="ml-auto w-28"
            onClick={() => nav({ to: "/company-info" })}
          >
            Provide Info
          </Button>
        )}
      </DropdownRow>

      <DropdownRow>
        Upload company documents
        {filesComplete ? (
          <span className="px-2 py-1 ml-auto flex flex-row gap-1 items-center rounded-md bg-positive bg-opacity-10 text-positive text-xs w-28">
            Completed
            <CheckIcon className="w-4 h-4 text-positive ml-auto" />
          </span>
        ) : (
          <Button
            variant="primary"
            className="ml-auto w-28"
            onClick={() =>
              nav({ to: "/company-info", search: { tab: "documents" } })
            }
          >
            Provide Info
          </Button>
        )}
      </DropdownRow>
    </Dropdown>
  );
};

export const CompanyPendingOnboardingsActions = () => {
  const { data, fetching } = useCompany();

  const nav = useNavigate();

  if (fetching) return;

  const casesWithPendingOrRejectedOnboardings =
    data?.getCompanyWorkspace.cases.filter(
      (caseData) =>
        !["approved", "denied", "rfe"].includes(caseData.status) &&
        (caseData.companyOnboarding?.employerDetailsNodeStatus === "pending" ||
          caseData.companyOnboarding?.employerDetailsNodeStatus === "rejected")
    ) ?? [];

  if (casesWithPendingOrRejectedOnboardings?.length === 0) return;

  return (
    <Dropdown
      icon={<AboutIcon />}
      title="Provide applicant information"
      defaultOpen
    >
      {casesWithPendingOrRejectedOnboardings.map((caseData) => (
        <CaseDropdownRow
          key={caseData.id}
          caseData={caseData}
          onClick={() => {
            nav({
              to: "/applicants/$caseId",
              params: { caseId: caseData.id.toString() },
            });
          }}
        />
      ))}
    </Dropdown>
  );
};

export const ReviewFinalPetitionActions = () => {};

export const CompanyTodoList = () => {
  return (
    <div className="w-full p-8 flex flex-col gap-2">
      <div className="flex flex-row items-center">
        <div className="flex flex-col gap-1">
          <h1 className="text-grey-100 text-sm font-semibold">
            Your to-do list
          </h1>
          <h2 className="text-sm text-grey-300">
            Find all the key actions you need to take to speed up visa process
            of your employees.
          </h2>
        </div>

        <div className="ml-auto">
          <RequestNewApplicantButton />
        </div>
      </div>

      <div className="mt-4 flex flex-col gap-4">
        <CompanyDetailsActions />
        <CompanyPendingOnboardingsActions />
        <SignSupportingDocumentsActions />
        <ReviewCaseActions />
      </div>
    </div>
  );
};
