import { useEditorContext } from "@/providers/editorProvider";
import { EditorContent, useEditor } from "@tiptap/react";
import { useEffect, useState } from "react";
import { Instance, Props } from "tippy.js";
import { FaRegCommentDots } from "react-icons/fa6";
import * as Y from "yjs";
import { useRef } from "react";
import { BubbleMenu, isNodeSelection } from "@tiptap/react";
import { Button } from "./button";
import { CommentYMap, useRerenderOnYObjectChange } from "@lighthouse/editor";

const HighlightMenu = () => {
  const instanceRef = useRef<Instance<Props> | null>(null);

  const { editor, provider, userId, userName } = useEditorContext();

  const [comments, setComments] = useState<CommentYMap[]>([]);

  const syncComments = () => {
    const commentsInDoc = provider?.document.getArray("comments");
    const res = [];
    for (let i = 0; i < (commentsInDoc ?? []).length; i++) {
      const comment = commentsInDoc?.get(i);
      if (comment == null) continue;
      res.push(comment as CommentYMap);
    }
    setComments(res);
  };

  useRerenderOnYObjectChange(
    provider?.document.getArray("comments"),
    syncComments
  );

  const doBlur = (instance: Instance) => {
    instance.hide();
  };

  const createComment = async () => {
    if (provider == null || editor == null) return;

    let index = -1;

    for (let i = 0; i < comments.length; i++) {
      if (
        comments[i].get("userId") === userId &&
        comments[i].get("completed") === false
      ) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      editor.commands.deleteCommentHighlightById(
        comments[index].get("id") as string
      );
      syncComments();
    }

    const randomId = `${Date.now()}-${String(Math.random()).slice(2)}`;

    const newComment: CommentYMap = new Y.Map([
      ["id", randomId],
      ["userId", userId],
      ["externalUserName", userName],
      ["timestamp", Date.now()],
      ["content", ""],
      ["internal", false],
      ["resolved", false],
      ["completed", false],
      ["lighthouseAuthor", false],
      ["replies", new Y.Array()],
    ]);

    provider.document.getArray("comments").unshift([newComment]);
    editor
      .chain()
      .focus()
      .setCommentHighlight({ id: randomId, internal: false })
      .run();

    syncComments();
  };

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 200,
        onClickOutside: doBlur,
        moveTransition: "transform 0.15s ease-out",
        onCreate: (val) => {
          instanceRef.current = val;
        },
      }}
      shouldShow={({ editor, state }) =>
        !editor.isActive("image") &&
        !state.selection.empty &&
        !isNodeSelection(state.selection)
      }
    >
      <Button
        variant="primary"
        onClick={createComment}
        className="px-3 py-1.5 flex items-center justify-center text-white flex-row gap-2 text-nowrap"
      >
        <FaRegCommentDots className="w-full" />
        <span>Suggest Changes</span>
      </Button>
    </BubbleMenu>
  );
};

export const TextEditorRenderer = (props: { disabled?: boolean }) => {
  const { disabled = false } = props;

  const { provider, extensions, ready, mode, setEditor } = useEditorContext();

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class:
            "prose prose-md leading-[1.5em] [&+p]:mt-[1em] [&_ol]:mt-[1em] [&_ul]:mt-[1em] [&_ol+p]:mt-[1em] [&_ul+p]:mt-[1em] [&+blockquote]:mt-[0.5em] [&_blockquote+blockquote]:mt-[1em]",
        },
      },
      extensions: extensions ?? [],
      editable: false,
    },
    [provider, extensions]
  );

  useEffect(() => {
    setEditor(editor);

    return () => {
      if (editor != null && !editor.isDestroyed) {
        setEditor(null);
        editor.destroy();
      }
    };
  }, [editor, setEditor]);

  return (
    provider != null &&
    ready && (
      <div>
        {editor && mode === "review" && !disabled && <HighlightMenu />}
        <EditorContent
          editor={editor}
          className="h-full w-full focus:outline-none focus:ring-0 text-justify"
        />
      </div>
    )
  );
};

export const TextEditor = (props: { disabled?: boolean }) => {
  const { provider, ready } = useEditorContext();

  return (
    <div className="bg-white h-fit min-h-[800px] w-[825px] p-20 rounded-lg shadow-modal">
      {provider != null && ready ? (
        <TextEditorRenderer {...props} />
      ) : (
        <div className="flex flex-col gap-10 animate-pulse">
          <ul className="w-9/12 space-y-5">
            <li className="w-3/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-4/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-4/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-6/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>

          <ul className="w-9/12 space-y-5">
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-8/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-11/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-10/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>

          <ul className="w-9/12 space-y-5">
            <li className="w-8/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-10/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-11/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>

          <ul className="w-9/12 space-y-5">
            <li className="w-8/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-full h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-11/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
            <li className="w-10/12 h-3 bg-grey-600 rounded-full dark:bg-grey-500"></li>
          </ul>
        </div>
      )}
    </div>
  );
};
