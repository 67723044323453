import { Heading } from "@radix-ui/themes";
import { LabelText } from "./typography";
import { getDashboardData, getOnboarding } from "@/lib/queries";
import { useAdminOverrideUserId, useUserContext } from "@/lib/hooks";
import { useQuery } from "urql";
import { OnboardingNodeGroups } from "@/providers/onboardingProvider";
import { AboutIcon, SupportLetterIcon } from "./icons/sidebar";
import { EmployerDocumentsIcon, WorkIcon } from "./icons/sidebar";
import {
  ArrowRightIcon,
  CheckCircledIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import { Button } from "./button";

import { DashboardProgressBar } from "./progress";
import { ResultOf } from "gql.tada";

import { useOnboardingData } from "@/providers/onboardingDataProvider";
import { useNavigate } from "@tanstack/react-router";
import { CaseStatusTimeline } from "./caseStatusTimeline";
import { Input } from "./inputs";
import { cn } from "@/lib/cn";

import { CompanyStats } from "./companyStats";
import { CompanyTodoList } from "./companyTodoList";
import { FormActions } from "./formActions";
import { PetitionPreviewCard } from "./petitionPreview";

import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { WarningIcon } from "./icons/warning";
import { LetterActions } from "./letterActions";

const timeGreeting = () => {
  const now = new Date();
  const hour = now.getHours();
  if (hour < 12) {
    return "Good Morning";
  } else if (hour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

const options: Partial<{
  [key in OnboardingNodeGroups]: {
    label: string;
    icon: React.ReactNode;
  };
}> = {
  about_you: {
    label: "About You",
    icon: <AboutIcon />,
  },
  work_experience: {
    label: "Your work experience",
    icon: <WorkIcon />,
  },
  support_letters: {
    label: "Support Letters",
    icon: <SupportLetterIcon />,
  },
  employer_documents: {
    label: "Employer Documents",
    icon: <EmployerDocumentsIcon />,
  },
};

const MissingInfoRowBadge = (props: {
  status: "submitted_for_review" | "pending" | "approved" | "rejected";
}) => {
  const statusClassNames = {
    pending:
      "bg-grey-300 bg-opacity-[8%] border border-grey-300 border-opacity-[8%] text-grey-300",
    approved:
      "bg-positive bg-opacity-[8%] border border-[rgba(32, 128, 17, 0.08)] text-positive",
    submitted_for_review:
      "bg-[#D2449114] bg-opacity-[8%] border border-[#D2449114] border-opacity-[8%] text-[#D24491]",
  };

  const statusIcons = {
    submitted_for_review: (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7.75C6.51777 7.75 6.9375 7.33027 6.9375 6.8125C6.9375 6.29473 6.51777 5.875 6 5.875C5.48223 5.875 5.0625 6.29473 5.0625 6.8125C5.0625 7.33027 5.48223 7.75 6 7.75Z"
          stroke="#D24491"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 7.75V8.875"
          stroke="#D24491"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.75 4.375H2.25C2.04289 4.375 1.875 4.54289 1.875 4.75V10C1.875 10.2071 2.04289 10.375 2.25 10.375H9.75C9.95711 10.375 10.125 10.2071 10.125 10V4.75C10.125 4.54289 9.95711 4.375 9.75 4.375Z"
          stroke="#D24491"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.125 4.375V2.875C4.125 2.37772 4.32254 1.90081 4.67417 1.54917C5.02581 1.19754 5.50272 1 6 1C6.49728 1 6.97419 1.19754 7.32583 1.54917C7.67746 1.90081 7.875 2.37772 7.875 2.875V4.375"
          stroke="#D24491"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    approved: <CheckCircledIcon />,
    pending: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8" clipPath="url(#clip0_2926_29059)">
          <path
            d="M3.375 11H2.625C2.52554 11 2.43016 10.9605 2.35984 10.8902C2.28951 10.8198 2.25 10.7245 2.25 10.625V9.125"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.625 2H7.125L9.75 4.625V6.875"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.25 3.5V2.375C2.25 2.27554 2.28951 2.18016 2.35984 2.10984C2.43016 2.03951 2.52554 2 2.625 2H3.75"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.125 2V4.625H9.75"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.75 8.75V10.625C9.75 10.7245 9.71049 10.8198 9.64017 10.8902C9.56984 10.9605 9.47446 11 9.375 11H9"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.25 5.375V7.25"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.25 11H7.125"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2926_29059">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  };

  const statusNames = {
    pending: "In Progress",
    approved: "Approved",
    submitted_for_review: "In Review",
  };

  const rejectedAsInProgress =
    props.status === "rejected" ? "pending" : props.status;

  const tooltipText = {
    pending:
      "This section is pending submission. The Lighthouse team will not be able to start the application process until this section is completed.",
    approved:
      "This section has been reviewed and approved by the Lighthouse team. The information in this section will be used in the visa application.",
    submitted_for_review:
      "This section has been submitted. The Lighthouse team is currently reviewing your submission, it usually takes up to 3 days.",
  };

  return (
    <Tooltip>
      <TooltipTrigger className="w-fit">
        <div
          className={cn(
            "flex flex-row items-center gap-1 px-2 py-1 text-xs font-semibold rounded-full",
            statusClassNames[rejectedAsInProgress]
          )}
        >
          {statusIcons[rejectedAsInProgress]}
          {statusNames[rejectedAsInProgress]}
        </div>
      </TooltipTrigger>
      <TooltipContent className="px-3 py-2 max-w-[268px] text-xs" side="top">
        {tooltipText[rejectedAsInProgress]}
      </TooltipContent>
    </Tooltip>
  );
};

const flattenRejectedFields = (
  rejected: {
    nodeId: number;
    rejectedFields: {
      key: string;
      fieldName: string;
      rejectionReason: string | undefined;
    }[];
  }[]
) => {
  const res = [];

  for (const node of rejected) {
    for (const field of node.rejectedFields) {
      res.push({
        nodeId: node.nodeId,
        name: field.fieldName,
        rejectionReason: field.rejectionReason,
      });
    }
  }

  return res;
};

const RejectedFieldDropdown = (props: {
  group: OnboardingNodeGroups;
  rejected: {
    nodeId: number;
    rejectedFields: {
      key: string;
      fieldName: string;
      rejectionReason: string | undefined;
    }[];
  }[];
}) => {
  const { rejected, group } = props;
  const nav = useNavigate();

  const navToNode = (nodeId: number) => {
    nav({
      to: `/group/$groupId`,
      params: { groupId: group },
      search: { nodeId },
    });
  };

  const flatRejectedFields = flattenRejectedFields(rejected);

  return (
    <div className="flex flex-col text-negative mb-1 hover:bg-grey-700 rounded-sm">
      <div className="flex flex-row items-center px-2 py-3 text-xs text-danger gap-2">
        <WarningIcon />
        Update rejected information
        <span className="font-light text-xs">{flatRejectedFields.length}</span>
      </div>

      <div className="flex flex-col px-[1.25em]">
        {flatRejectedFields.map((field, idx) => (
          <div
            className="flex flex-row w-full items-center p-2 text-xs border-b border-b-grey-600 last:border-none"
            key={`rejected-field-${idx}`}
          >
            <span className="text-grey-100">{field.rejectionReason}</span>

            <button
              className="ml-auto text-xs text-danger"
              onClick={() => navToNode(field.nodeId)}
            >
              Review
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const MissingInfoRow = (props: {
  label: string;
  icon: React.ReactNode;
  completed: number;
  total: number;
  rejected: {
    nodeId: number;
    rejectedFields: {
      key: string;
      fieldName: string;
      rejectionReason: string | undefined;
    }[];
  }[];
  onboardingGroup: OnboardingNodeGroups;
  onClick?: () => void;
  internalStatus?: "pending" | "submitted_for_review" | "approved" | "rejected";
}) => {
  const { label, icon, completed, total, onClick, internalStatus, rejected } =
    props;

  return (
    <div className="w-full flex flex-col border-b border-b-grey-600">
      <div className="w-full grid grid-cols-4 px-2 py-3 items-center ">
        <div className="flex flex-row items-center gap-2">
          {icon}
          <span className="text-xs text-neutral-600">{label}</span>
        </div>

        {internalStatus != null && (
          <>
            <div className="flex flex-row items-center gap-2 text-grey-300 text-[11px]">
              {internalStatus === "submitted_for_review" ||
              internalStatus === "approved" ? (
                <>
                  <div className="w-[1.5rem] text-nowrap">100%</div>
                  <div className="w-full flex flex-row items-center gap-2">
                    <DashboardProgressBar completed={1} total={1} />
                  </div>
                </>
              ) : (
                <>
                  <div className="w-[1.5rem] text-nowrap">
                    {completed == 0 ? 0 : Math.round((completed / total) * 100)}
                    %
                  </div>
                  <div className="w-full flex flex-row items-center gap-2">
                    <DashboardProgressBar completed={completed} total={total} />
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-row items-center gap-2">
              <MissingInfoRowBadge status={internalStatus} />
            </div>

            <div className="flex justify-end w-full">
              <Button
                variant={
                  ["rejected", "pending"].includes(internalStatus)
                    ? "primary"
                    : "secondary"
                }
                onClick={onClick}
              >
                <div className="w-full flex flex-row items-center justify-center gap-2 min-w-[80px]">
                  {["rejected", "pending"].includes(internalStatus) ? (
                    <>
                      Complete
                      <ArrowRightIcon />
                    </>
                  ) : (
                    "View"
                  )}
                </div>
              </Button>
            </div>
          </>
        )}
      </div>
      {rejected.length > 0 && (
        <RejectedFieldDropdown
          rejected={rejected}
          group={props.onboardingGroup}
        />
      )}
    </div>
  );
};

const MissingInformation = () => {
  const { progress } = useOnboardingData();
  const availableOptions = Object.keys(progress).filter(
    (x) => options[x as OnboardingNodeGroups] != null
  );
  const nav = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <LabelText className="text-sm text-grey-100" as="span">
          Complete your onboarding
        </LabelText>
        <LabelText className="text-sm text-grey-300" as="span">
          The Lighthouse team will need the following information to complete
          your onboarding.
        </LabelText>
      </div>

      <div className="flex flex-col px-1">
        {availableOptions.map((option) => {
          const opt = options[option as OnboardingNodeGroups];
          if (opt == null) return;

          const { label, icon } = opt;

          const progressGroup = progress[option as OnboardingNodeGroups];

          if (progressGroup == null) return;

          const everythingSubmitted =
            progressGroup.submittedNodes === progressGroup.totalNodes;
          const anythingRejected = progressGroup.rejectedNodes > 0;

          const everythingApproved =
            progressGroup.approvedNodes === progressGroup.totalNodes;

          const internalStatus = anythingRejected
            ? "rejected"
            : everythingSubmitted
              ? "submitted_for_review"
              : everythingApproved
                ? "approved"
                : "pending";

          // get rejected fields from all nodes
          const rejectedFields = [];
          for (const node of Object.keys(progressGroup.nodeProgress)) {
            const nodeRejected =
              progressGroup.nodeProgress[parseInt(node)]?.rejectedFields ?? [];

            if (nodeRejected.length === 0) continue;

            rejectedFields.push({
              nodeId: parseInt(node),
              rejectedFields: nodeRejected,
            });
          }

          return (
            <MissingInfoRow
              key={option}
              label={label}
              icon={icon}
              completed={progressGroup.completedNodes}
              total={progressGroup.totalNodes}
              rejected={rejectedFields}
              onClick={() => nav({ to: `/group/${option}` })}
              internalStatus={internalStatus}
              onboardingGroup={option as OnboardingNodeGroups}
            />
          );
        })}
      </div>
    </div>
  );
};

const ReviewCasePending = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col gap-1">
        <LabelText className="text-sm text-grey-100" as="span">
          Review your visa application
        </LabelText>

        <LabelText className="text-sm text-grey-300" as="span">
          We are working on your final application, it will be available for
          review soon.
        </LabelText>
      </div>
    </div>
  );
};

const ReviewCase = (props: {
  petition: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
}) => {
  const { petition } = props;

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <LabelText className="text-sm text-grey-100" as="span">
          Review your visa application
        </LabelText>

        <LabelText className="text-sm text-grey-300" as="span">
          {petition.status === "pending"
            ? "Your application is ready for review."
            : petition.status === "changes_requested"
              ? "You have suggested changes to this application. We are working on changes, and a new version will be available soon."
              : "You have approved this application. No further action is needed."}
        </LabelText>
      </div>
      <PetitionPreviewCard compiledCaseAction={petition} />
    </div>
  );
};

const validateReceiptNotice = (value: string) => {
  if (value.length !== 13) return false;
  const regex = /^(?:[A-Z]{3}|[A-Z]{2})\d{10}$/;
  return regex.test(value);
};

const ReceiptNotice = () => {
  const { receiptNotice, publishedCase } = useOnboardingData();

  const validNumber = validateReceiptNotice(receiptNotice);

  const missingReceiptNotice =
    receiptNotice == null ||
    receiptNotice.startsWith("[UNKNOWN") ||
    receiptNotice.length === 0;

  return (
    <div className="w-full h-full flex justify-center pt-16">
      <div className="min-w-[400px] max-w-[482px] lg:min-w-[482px] flex flex-col gap-4">
        {missingReceiptNotice && (
          <>
            <img src="/icons/receipt-notice.png" className="w-[140px]" />
            <p className="text-grey-300 text-xs">
              USCIS will send the 13-character receipt number to follow your
              visa decision to your employer's email address. Your employer
              should then forward this information to{" "}
              <a
                href="mailto:notices@lighthousehq.com"
                className="font-[525] text-grey-200"
              >
                notices@lighthousehq.com
              </a>{" "}
              <br />
              <br />
              If you have already received the email with your case number, you
              can also forward it to the same email. This way, we can stay up to
              date with your status and keep you informed of any changes.
            </p>
          </>
        )}

        {!missingReceiptNotice && (
          <img src="/icons/pending.png" className="w-[140px]" />
        )}

        <div className="flex flex-col gap-2">
          <LabelText as="span" className="text-xs font-[460]">
            Your 13-character receipt number
          </LabelText>

          <div className="flex flex-row items-center relative">
            <Input
              value={receiptNotice}
              disabled
              placeholder="Forward receipt notice email to notices@lighthousehq.com"
              type="text"
              className="w-full"
            />

            {validNumber && (
              <CheckCircledIcon className="w-4 h-4 text-positive absolute right-3" />
            )}

            {!validNumber && (
              <ExclamationTriangleIcon className="w-4 h-4 text-negative absolute right-3" />
            )}
          </div>
        </div>

        {!missingReceiptNotice && (
          <p className="text-grey-300 text-xs">
            We've received your receipt notice. Here are the details for your
            awareness. From this receipt notice date, USCIS has {""}
            <span className="font-[525] text-grey-200">
              15 business days
            </span>{" "}
            to respond with an outcome. <br />
            <br />
            You can always check your case status at the USCIS online site here
            using your receipt notice number at{" "}
            <a
              href="https://egov.uscis.gov/"
              className="text-blue hover:underline"
            >
              https://egov.uscis.gov
            </a>
          </p>
        )}

        {publishedCase != null && <ReviewCase petition={publishedCase} />}
      </div>
    </div>
  );
};

const Shipped = () => {
  const { trackingNumber, publishedCase } = useOnboardingData();
  return (
    <div className="w-full h-full flex flex-col items-center gap-8">
      <div className="w-1/3 flex flex-col gap-4 pt-16">
        <div className="flex w-full items-center justify-center">
          <img src="/icons/shipped.png" className="w-[163px]" />
        </div>

        {trackingNumber != null && (
          <p className="text-grey-300 text-xs text-center">
            Your visa application has been sent to the USCIS office. You can
            track its status on the carrier's website{" "}
            <a
              href={`https://www.fedex.com/wtrk/track/?tracknumbers=${trackingNumber}`}
              className="text-blue hover:underline"
            >
              {trackingNumber}
            </a>
          </p>
        )}

        {trackingNumber == null && (
          <p className="text-grey-300 text-xs text-center">
            Your visa application is enroute to the USCIS office. We will update
            this page when a tracking number is available.
          </p>
        )}
      </div>
      {publishedCase != null && <ReviewCase petition={publishedCase} />}
    </div>
  );
};

const Approved = () => {
  const { visaClass } = useOnboardingData();
  const { userEntity } = useUserContext();

  return (
    <div className="w-full h-full bg-[url(/backgrounds/approved.webp)] bg-right-bottom bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col pt-[10%]">
        <div className="flex flex-col gap-2 items-center">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.9993 29.3337C23.3631 29.3337 29.3327 23.3641 29.3327 16.0003C29.3327 8.63653 23.3631 2.66699 15.9993 2.66699C8.63555 2.66699 2.66602 8.63653 2.66602 16.0003C2.66602 23.3641 8.63555 29.3337 15.9993 29.3337Z"
              fill="#DEEDDC"
              stroke="#208011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 17L14 20L21 13"
              stroke="#208011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Heading align="center" className="font-[600] text-xl">
            Congratulations {userEntity?.firstName ?? "User"}, your <br />
            {visaClass.replaceAll("_", "-").toLocaleUpperCase()} application has
            been approved!
          </Heading>
          <span className="text-grey-300 text-sm">
            Check your email for next steps.
          </span>
        </div>
      </div>
    </div>
  );
};

const Denied = () => {
  return <div>Case is denied</div>;
};

const RFE = () => {
  const { publishedCase } = useOnboardingData();
  return (
    <div className="w-full h-full flex justify-center pt-4 pb-12">
      <div className="w-[482px] flex flex-col gap-4">
        <h1 className="text-grey-200 text-md font-[525]">
          Unfortunately, USCIS has issued a Request for Evidence (RFE)
        </h1>
        <p className="text-xs text-grey-300">
          While this is not the outcome we hoped for, about 1/3 of cases
          nationally get RFEd and we will ensure we prepare a strong response to
          maximize chances of your approval. <br />
          In terms of immediate next steps, we're awaiting USCIS for a digital
          copy of the RFE via fax. <br /> <br />A paper copy will be mailed to
          your mailing address indicated on the I-907 in the next 1-2 weeks. If
          we do not receive the digital faxed copy in the next 24 hours, we'll
          share with you a template email to request a digital copy from USCIS
          which we've experienced is typically faster than the government's
          e-request service. <br /> <br />
          Once we receive a copy, we will review the challenges and share a case
          strategy for the response. We'll immediately get started on preparing
          a robust response. USCIS issues RFEs with up to 3 months to prepare
          and file a response. Depending on the severity of the RFE, I
          anticipate it will take us 1-3 weeks to prepare a response. Once
          submitted, USCIS has the same 15 business day premium processing
          window to revert with a final outcome. <br /> <br />
          For transparency, there are no additional RFE fees associated with
          your case, and in the worst case outcome of a denial, we will review
          and prepare a refiling swiftly. Our goal is to ensure we help you get
          to the finish line.
        </p>
        {publishedCase != null && <ReviewCase petition={publishedCase} />}
      </div>
    </div>
  );
};

const DashboardContent = () => {
  const { caseStatus, onboardingId, publishedCase } = useOnboardingData();

  return (
    <div
      className={cn(
        "flex flex-col w-full h-full rounded-b-xl gap-8 px-8",
        caseStatus === "approved" && "p-0"
      )}
    >
      {["pending_documents", "in_progress"].includes(caseStatus ?? "") && (
        <>
          {publishedCase != null && <ReviewCase petition={publishedCase} />}
          <MissingInformation />
          <LetterActions
            onboardingId={onboardingId}
            recipient="beneficiary"
            compact
          />
          <FormActions onboardingId={onboardingId} recipient="beneficiary" />
        </>
      )}

      {caseStatus === "in_final_review" && (
        <>
          {publishedCase != null ? (
            <ReviewCase petition={publishedCase} />
          ) : (
            <ReviewCasePending />
          )}
          <LetterActions
            onboardingId={onboardingId}
            recipient="beneficiary"
            compact
          />
          <FormActions onboardingId={onboardingId} recipient="beneficiary" />
        </>
      )}
      {["submitted", "shipped"].includes(caseStatus ?? "") && (
        <>
          <Shipped />
        </>
      )}

      {["delivered", "in_review_uscis"].includes(caseStatus ?? "") && (
        <>
          <ReceiptNotice />
        </>
      )}

      {caseStatus === "approved" && <Approved />}
      {caseStatus === "denied" && <Denied />}
      {caseStatus === "rfe" && <RFE />}
    </div>
  );
};

const nicerVisaClassName: Record<string, string> = {
  o_1a_agent: "O-1A Agent",
  o_1a: "O-1A",
  TN: "TN",
  H_1B_TRANSFER: "H-1B Transfer",
  E_3: "E-3 Nonimmigrant",
  EB_1A: "EB-1A",
  EB_2_NIW: "EB-2 Nonimmigrant",
};

export const Dashboard = (props: { onboardingId: number }) => {
  const { visaClass, caseStatus } = useOnboardingData();

  const overrideUserId = useAdminOverrideUserId();
  const [{ data }] = useQuery({
    query: getDashboardData,
    variables: {
      id: props.onboardingId,
      overrideUserId: overrideUserId,
    },
  });

  return (
    <div className="w-full h-full flex flex-col bg-grey-800 rounded-xl gap-6 overflow-y-scroll">
      {caseStatus !== "approved" && (
        <div className="flex flex-col w-full bg-grey-700 rounded-t-xl">
          <div
            className="flex flex-col w-full h-full pt-4 gap-8 pl-8"
            style={{
              background:
                "linear-gradient(267.15deg, rgba(241, 241, 241, 0) 54.03%, #F1F1F1 88.14%), url('/backgrounds/user-dashboard.webp')",
              backgroundBlendMode: "normal, color-burn",
              backgroundRepeat: "no-repeat, no-repeat",
              backgroundSize: "cover, cover",
            }}
          >
            <div className="flex flex-col gap-1">
              <Heading className="font-sans font-[500] text-[18px]">
                {timeGreeting()},{" "}
                {data?.context.userEntity?.firstName ?? "User"}
              </Heading>
              <LabelText className="text-sm text-grey-200" as="span">
                Here's an overview of your{" "}
                {nicerVisaClassName[visaClass] ??
                  visaClass.replace("_", "-").toLocaleUpperCase()}{" "}
                visa application.
              </LabelText>
            </div>

            <CaseStatusTimeline currentCaseStatus={caseStatus} scrollable />
          </div>
        </div>
      )}
      <DashboardContent />
    </div>
  );
};

export const CompanyDashboard = () => {
  return (
    <div className="w-full h-full flex flex-col bg-grey-800 rounded-xl overflow-y-scroll">
      <div className="flex flex-col w-full bg-grey-700 rounded-t-xl">
        <div className="flex flex-col w-full h-full py-8 gap-6 pl-8 bg-[url('/backgrounds/employer-dashboard.webp')] bg-norepeat bg-cover">
          <div className="flex flex-col gap-2">
            <Heading className="font-[600] text-2xl">{timeGreeting()}</Heading>
            <LabelText className="text-sm text-grey-300" as="span">
              Here's an overview of the visa application progress for all your
              employees.
            </LabelText>
          </div>
        </div>
      </div>

      <div className="flex flex-col h-full rounded-b-xl">
        <CompanyStats />
        <CompanyTodoList />
      </div>
    </div>
  );
};
