export const SupportLetterPlaceholder = () => {
  return (
    <div className="flex flex-row items-center p-3 rounded-md gap-4 bg-grey-800 mr-12 ring-1 ring-grey-600 mt-5">
      <div className="flex items-center justify-center w-[80px] h-[40px] bg-grey-500 flex-shrink-0 rounded-sm overflow-hidden">
        <div className="w-[70%] h-full bg-white rounded-sm shadow-lg mt-5 p-2 text-[3px]">
          ~~~~~~~~
          <br />
          ~~~~~~~~~~~~~~~~~
          <br />
          ~~~~~~~~~~~~~~
          <br />
          ~~~~~~~~~~~~~~
        </div>
      </div>

      <div className="text-[12px] text-grey-300">
        Once you submit your signers, the Lighthouse team will verify and get
        started on drafting your expert letter.{" "}
        <span className="text-grey-200">
          Your draft letter will appear on your dashboard for review and
          approval in the coming days.
        </span>
      </div>
    </div>
  );
};

export const SupportLetterPreview = () => {
  return (
    <div className="w-full pr-12">
      <div className="flex flex-row items-center p-3 rounded-md gap-4 bg-grey-200 w-full">
        <div className="flex items-center justify-center w-[170px] h-[90px] bg-grey-300 flex-shrink-0 rounded-sm overflow-hidden">
          <div className="w-[70%] h-full bg-white rounded-sm shadow-lg mt-10 p-3 pt-5 text-[3px]">
            ~~~~~~~~
            <br />
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            <br />
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          </div>
        </div>

        <div className="flex flex-col justify-between h-full w-full gap-2">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center">
              <span className="text-[12px] text-grey-400">
                Edited Jul 14, 3:20pm
              </span>
              <button className="ml-auto px-3 py-[3px] text-[#81CA67] text-[11px] font-medium bg-[#81CA67] bg-opacity-10 rounded-full ring-1 ring-[#81CA67] ring-opacity-25 shadow-positive-2">
                Ready to review
              </button>
            </div>

            <span className="text-grey-600 text-[14px]">
              Critical Role at Google DeepMind
            </span>
          </div>

          <button className="w-fit bg-grey-700 rounded-[6px] px-2.5 py-1 text-[14px] font-medium text-grey-200">
            Review
          </button>
        </div>
      </div>
    </div>
  );
};
