import { useAuth } from "@clerk/clerk-react";
import { RefObject, useEffect, useState } from "react";

import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import {
  getOnboardingNode,
  getUserContext,
  getWorkspaces,
  patchUserEntityMutation,
  subscribeToOnboardingNodeChange,
  updateOnboardingNode,
} from "./queries";
import { useMutation, useQuery, useSubscription } from "urql";
import { parseFormValues, FormValues } from "./formValues";
import { useOnboarding } from "@/providers/onboardingProvider";
import { ResultOf } from "gql.tada";
import { PhoneNumber } from "@/components/inputs";
import { useNavigate } from "@tanstack/react-router";

declare global {
  interface Window {
    lighthouse: {
      overrideUserId: (userId: number) => void;
      clearOverrideUserId: () => void;
      token: () => void;
    };
  }
}

type AdminStateType = {
  overrideUserId: number | null;
  setOverrideUserId: (value: number | null) => void;
};

export const useAdminStore = create(
  persist<AdminStateType>(
    (set) => ({
      overrideUserId: null,
      setOverrideUserId: (value: number | null) =>
        set({ overrideUserId: value }),
    }),
    {
      name: "lighthouse-admin-store", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useAdminFunctions = () => {
  const { getToken } = useAuth();
  const overrideUserId = useAdminStore((state) => state.setOverrideUserId);

  // eslint-disable-next-line react-compiler/react-compiler
  window.lighthouse = {
    overrideUserId: (userId: number) => {
      overrideUserId(userId);
    },
    clearOverrideUserId: () => {
      overrideUserId(null);
    },
    token: async () => {
      const token = await getToken();
      if (token == null) return null;
      console.log(token);
    },
  };
};

export const useDebounce = <T>(value: T, delay: number): T | undefined => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useAdminOverrideUserId = () => {
  const { overrideUserId } = useAdminStore((state) => state);
  return overrideUserId;
};

export const useUserContext = (opts?: {
  requestPolicy?: "cache-and-network" | "cache-first" | "network-only";
}) => {
  const { overrideUserId } = useAdminStore((state) => state);

  const [{ data, fetching }] = useQuery({
    query: getUserContext,
    variables: {
      userContextInput: {
        overrideUserId,
      },
    },
    requestPolicy: opts?.requestPolicy ?? "network-only",
  });

  const doPatchUserEntity = useMutation(patchUserEntityMutation)[1];

  const doUpdateUserEntity = (
    key: keyof NonNullable<
      ResultOf<typeof getUserContext>["context"]["userEntity"]
    >,
    value: string | PhoneNumber
  ) => {
    if (data?.context.userEntity == null) return;

    doPatchUserEntity({
      input: {
        userId: data.context.contextUserId,
        data: {
          [key]: value,
        },
      },
    });
  };

  return {
    loggedInUserId: data?.context.loggedInCustomerUserId,
    userId: data?.context.contextUserId,
    fetching,
    userEntity: data?.context.userEntity,
    doUpdateUserEntity,
  };
};

export const useOnboardingNodeData = (id: number) => {
  const [{ data, error, fetching }, refetch] = useQuery({
    query: getOnboardingNode,
    variables: { id: id },
    pause: id == null || id === -1,
  });

  const [, mutation] = useMutation(updateOnboardingNode);

  const contentData = parseFormValues(data?.onboardingNode.content);

  const doSetData = async (
    key: string,
    value?:
      | string
      | boolean
      | number
      | Record<string, string>
      | string[]
      | object,
    clear?: boolean
  ) => {
    if (data == null || fetching) return;

    const wasFieldPreviouslyRejected = contentData[key]?.status === "rejected";
    const wasFieldNotSubmitted =
      contentData[key]?.status === "not-submitted" ||
      contentData[key]?.status == undefined;

    const rejectedValue = wasFieldPreviouslyRejected
      ? contentData[key]?.value
      : contentData[key]?.rejectedValue;

    const newKeyValue: FormValues[string] = {
      value,
      status: wasFieldPreviouslyRejected
        ? "submitted"
        : wasFieldNotSubmitted
          ? "submitted"
          : contentData[key]?.status,
      rejectedValue,
    };

    const newContent: FormValues = clear
      ? {
          [key]: newKeyValue,
        }
      : {
          ...contentData,
          [key]: newKeyValue,
        };

    mutation({
      input: {
        onboardingNodeId: id,
        data: newContent,
      },
    });
  };

  useSubscription(
    {
      query: subscribeToOnboardingNodeChange,
      variables: { id: id },
      pause: id == null,
    },
    () => {
      refetch({ requestPolicy: "cache-and-network" });
    }
  );

  return {
    data: contentData,
    fetching,
    error,
    doSetData,
    customerCreated: data?.onboardingNode.customerCreated ?? false,
  };
};

export const useDataField = (
  key: string
): FormValues[string] & { disabled: boolean } => {
  const { currentOnboardingNodeId, nodeStatus } = useOnboarding();
  const { data } = useOnboardingNodeData(currentOnboardingNodeId ?? -1);

  return {
    value: data[key]?.value,
    status: data[key]?.status,
    rejectionReason: data[key]?.rejectionReason,
    disabled: nodeStatus === "submitted_for_review",
  };
};

const computeRect = (element: HTMLElement | null) => {
  if (element == null) return { width: 0, height: 0, x: 0, y: 0 };

  const { width, height, x, y } = element.getBoundingClientRect();

  return { width, height, x, y };
};

export const useResizeObserver = (ref: RefObject<HTMLElement | null>) => {
  const [rect, setRect] = useState(computeRect(ref.current));

  useEffect(() => {
    if (ref.current == null) return;

    setRect(computeRect(ref.current));

    const ob = new ResizeObserver(() => setRect(computeRect(ref.current)));
    ob.observe(ref.current);

    return () => ob.disconnect();
  }, [ref]);

  return rect;
};

type Workspace = {
  id: string;
  name: string;
  selectedOnboardingId?: number;
  onboardings: {
    id: number;
  }[];
};

type WorkspaceStateType = {
  selectedWorkspace?: Workspace;
  setSelectedWorkspace: (workspace?: Workspace) => void;
};

export const useWorkspaceStore = create(
  persist<WorkspaceStateType>(
    (set) => ({
      setSelectedWorkspace: (value?: Workspace) => {
        set({ selectedWorkspace: value });
      },
    }),
    {
      name: "lighthouse-workspace-store-1",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useWorkspaces = () => {
  const overrideUserId = useAdminOverrideUserId();
  const nav = useNavigate();
  const { selectedWorkspace, setSelectedWorkspace } = useWorkspaceStore();

  const [{ data, fetching }, refetch] = useQuery({
    query: getWorkspaces,
    variables: {
      overrideUserId,
    },
    requestPolicy: "cache-first",
  });

  const doSetSelectedWorkspace = (
    workspace?: Workspace,
    dontNavigate?: boolean
  ) => {
    setSelectedWorkspace(workspace);

    if (dontNavigate) return;
    nav({ to: `/home` });
  };

  useEffect(() => {
    if (data?.getWorkspaces == null) return;
    if (data.getWorkspaces.length === 0) {
      return;
    }

    if (
      selectedWorkspace == null ||
      data.getWorkspaces.find((x) => x.id === selectedWorkspace.id) == null
    ) {
      setSelectedWorkspace({
        id: data.getWorkspaces[0].id,
        name: data.getWorkspaces[0].name,
        onboardings: data.getWorkspaces[0].onboardings,
        selectedOnboardingId:
          data.getWorkspaces[0].onboardings.length > 0
            ? data.getWorkspaces[0].onboardings[0].id
            : undefined,
      });
    }
  }, [selectedWorkspace, data, setSelectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace == null) return;
    if (
      selectedWorkspace.onboardings != null &&
      selectedWorkspace.onboardings.length === 0
    ) {
      return;
    }

    if (
      selectedWorkspace.selectedOnboardingId != null &&
      selectedWorkspace.onboardings.filter(
        (x) => x.id === selectedWorkspace.selectedOnboardingId
      ).length !== 0
    )
      return;

    setSelectedWorkspace({
      ...selectedWorkspace,
      selectedOnboardingId: selectedWorkspace.onboardings[0].id,
    });
  }, []);

  return {
    fetching,
    workspaces: data?.getWorkspaces ?? [],
    selectedWorkspace,
    setSelectedWorkspace: doSetSelectedWorkspace,
    refetchWorkspaces: refetch,
  };
};

export type collaboratorType = {
  clientId: number;
  user: { color: string; name: string };
  cursor?: { anchor: number; head: number } | null;
  lastEdit?: number;
};

export const SYNC_SERVER_BASE_URL =
  import.meta.env.VITE_SYNC_SERVER_BASE_URL ??
  ("sync-server-bzr4.onrender.com" as const);

export type TutorialModalState = {
  modalShown: boolean;
  setModalShown: (value: boolean) => void;
};

export type GlobalModalState = {
  employer: TutorialModalState;
  letter: TutorialModalState;
  form: TutorialModalState;
  petitionReview: TutorialModalState;
};

export const useTutorialModalState = create(
  persist<GlobalModalState>(
    (set) => ({
      employer: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            employer: { ...state.employer, modalShown: value },
          })),
      },
      letter: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            letter: { ...state.letter, modalShown: value },
          })),
      },
      form: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            form: { ...state.form, modalShown: value },
          })),
      },
      petitionReview: {
        modalShown: false,
        setModalShown: (value: boolean) =>
          set((state) => ({
            petitionReview: { ...state.petitionReview, modalShown: value },
          })),
      },
    }),
    {
      name: "lighthouse-tutorial-modal-state",
      storage: createJSONStorage(() => localStorage),
      merge: (persistedState, currentState) => ({
        employer: {
          modalShown:
            (persistedState as any)?.employer?.modalShown ??
            currentState.employer.modalShown,
          setModalShown: currentState.employer.setModalShown,
        },
        letter: {
          modalShown:
            (persistedState as any)?.letter?.modalShown ??
            currentState.letter.modalShown,
          setModalShown: currentState.letter.setModalShown,
        },
        form: {
          modalShown:
            (persistedState as any)?.form?.modalShown ??
            currentState.form.modalShown,
          setModalShown: currentState.form.setModalShown,
        },
        petitionReview: {
          modalShown:
            (persistedState as any)?.petitionReview?.modalShown ??
            currentState.petitionReview.modalShown,
          setModalShown: currentState.petitionReview.setModalShown,
        },
      }),
    }
  )
);
