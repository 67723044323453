/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as NotFoundImport } from './routes/not-found'
import { Route as PortalImport } from './routes/_portal'
import { Route as LoginImport } from './routes/_login'
import { Route as IndexImport } from './routes/index'
import { Route as PortalHomeImport } from './routes/_portal/home'
import { Route as PortalFormImport } from './routes/_portal/form'
import { Route as LoginSplatImport } from './routes/_login/$'
import { Route as PortalCompanyInfoIndexImport } from './routes/_portal/company-info/index'
import { Route as PortalApplicantsIndexImport } from './routes/_portal/applicants/index'
import { Route as PortalGroupGroupIdImport } from './routes/_portal/group/$groupId'
import { Route as PortalApplicantsCaseIdImport } from './routes/_portal/applicants/$caseId'
import { Route as ExternalLetterSignLetterActionIdImport } from './routes/external/letter/sign/$letterActionId'
import { Route as ExternalLetterReviewLetterActionIdImport } from './routes/external/letter/review/$letterActionId'
import { Route as PortalWorkspacesInviteInviteIdImport } from './routes/_portal/workspaces/invite/$inviteId'
import { Route as PortalLetterSignLetterActionIdImport } from './routes/_portal/letter/sign/$letterActionId'
import { Route as PortalLetterReviewLetterActionIdImport } from './routes/_portal/letter/review/$letterActionId'

// Create/Update Routes

const NotFoundRoute = NotFoundImport.update({
  id: '/not-found',
  path: '/not-found',
  getParentRoute: () => rootRoute,
} as any)

const PortalRoute = PortalImport.update({
  id: '/_portal',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/_login',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PortalHomeRoute = PortalHomeImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => PortalRoute,
} as any)

const PortalFormRoute = PortalFormImport.update({
  id: '/form',
  path: '/form',
  getParentRoute: () => PortalRoute,
} as any)

const LoginSplatRoute = LoginSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => LoginRoute,
} as any)

const PortalCompanyInfoIndexRoute = PortalCompanyInfoIndexImport.update({
  id: '/company-info/',
  path: '/company-info/',
  getParentRoute: () => PortalRoute,
} as any)

const PortalApplicantsIndexRoute = PortalApplicantsIndexImport.update({
  id: '/applicants/',
  path: '/applicants/',
  getParentRoute: () => PortalRoute,
} as any)

const PortalGroupGroupIdRoute = PortalGroupGroupIdImport.update({
  id: '/group/$groupId',
  path: '/group/$groupId',
  getParentRoute: () => PortalRoute,
} as any)

const PortalApplicantsCaseIdRoute = PortalApplicantsCaseIdImport.update({
  id: '/applicants/$caseId',
  path: '/applicants/$caseId',
  getParentRoute: () => PortalRoute,
} as any)

const ExternalLetterSignLetterActionIdRoute =
  ExternalLetterSignLetterActionIdImport.update({
    id: '/external/letter/sign/$letterActionId',
    path: '/external/letter/sign/$letterActionId',
    getParentRoute: () => rootRoute,
  } as any)

const ExternalLetterReviewLetterActionIdRoute =
  ExternalLetterReviewLetterActionIdImport.update({
    id: '/external/letter/review/$letterActionId',
    path: '/external/letter/review/$letterActionId',
    getParentRoute: () => rootRoute,
  } as any)

const PortalWorkspacesInviteInviteIdRoute =
  PortalWorkspacesInviteInviteIdImport.update({
    id: '/workspaces/invite/$inviteId',
    path: '/workspaces/invite/$inviteId',
    getParentRoute: () => PortalRoute,
  } as any)

const PortalLetterSignLetterActionIdRoute =
  PortalLetterSignLetterActionIdImport.update({
    id: '/letter/sign/$letterActionId',
    path: '/letter/sign/$letterActionId',
    getParentRoute: () => PortalRoute,
  } as any)

const PortalLetterReviewLetterActionIdRoute =
  PortalLetterReviewLetterActionIdImport.update({
    id: '/letter/review/$letterActionId',
    path: '/letter/review/$letterActionId',
    getParentRoute: () => PortalRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_login': {
      id: '/_login'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_portal': {
      id: '/_portal'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PortalImport
      parentRoute: typeof rootRoute
    }
    '/not-found': {
      id: '/not-found'
      path: '/not-found'
      fullPath: '/not-found'
      preLoaderRoute: typeof NotFoundImport
      parentRoute: typeof rootRoute
    }
    '/_login/$': {
      id: '/_login/$'
      path: '/$'
      fullPath: '/$'
      preLoaderRoute: typeof LoginSplatImport
      parentRoute: typeof LoginImport
    }
    '/_portal/form': {
      id: '/_portal/form'
      path: '/form'
      fullPath: '/form'
      preLoaderRoute: typeof PortalFormImport
      parentRoute: typeof PortalImport
    }
    '/_portal/home': {
      id: '/_portal/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof PortalHomeImport
      parentRoute: typeof PortalImport
    }
    '/_portal/applicants/$caseId': {
      id: '/_portal/applicants/$caseId'
      path: '/applicants/$caseId'
      fullPath: '/applicants/$caseId'
      preLoaderRoute: typeof PortalApplicantsCaseIdImport
      parentRoute: typeof PortalImport
    }
    '/_portal/group/$groupId': {
      id: '/_portal/group/$groupId'
      path: '/group/$groupId'
      fullPath: '/group/$groupId'
      preLoaderRoute: typeof PortalGroupGroupIdImport
      parentRoute: typeof PortalImport
    }
    '/_portal/applicants/': {
      id: '/_portal/applicants/'
      path: '/applicants'
      fullPath: '/applicants'
      preLoaderRoute: typeof PortalApplicantsIndexImport
      parentRoute: typeof PortalImport
    }
    '/_portal/company-info/': {
      id: '/_portal/company-info/'
      path: '/company-info'
      fullPath: '/company-info'
      preLoaderRoute: typeof PortalCompanyInfoIndexImport
      parentRoute: typeof PortalImport
    }
    '/_portal/letter/review/$letterActionId': {
      id: '/_portal/letter/review/$letterActionId'
      path: '/letter/review/$letterActionId'
      fullPath: '/letter/review/$letterActionId'
      preLoaderRoute: typeof PortalLetterReviewLetterActionIdImport
      parentRoute: typeof PortalImport
    }
    '/_portal/letter/sign/$letterActionId': {
      id: '/_portal/letter/sign/$letterActionId'
      path: '/letter/sign/$letterActionId'
      fullPath: '/letter/sign/$letterActionId'
      preLoaderRoute: typeof PortalLetterSignLetterActionIdImport
      parentRoute: typeof PortalImport
    }
    '/_portal/workspaces/invite/$inviteId': {
      id: '/_portal/workspaces/invite/$inviteId'
      path: '/workspaces/invite/$inviteId'
      fullPath: '/workspaces/invite/$inviteId'
      preLoaderRoute: typeof PortalWorkspacesInviteInviteIdImport
      parentRoute: typeof PortalImport
    }
    '/external/letter/review/$letterActionId': {
      id: '/external/letter/review/$letterActionId'
      path: '/external/letter/review/$letterActionId'
      fullPath: '/external/letter/review/$letterActionId'
      preLoaderRoute: typeof ExternalLetterReviewLetterActionIdImport
      parentRoute: typeof rootRoute
    }
    '/external/letter/sign/$letterActionId': {
      id: '/external/letter/sign/$letterActionId'
      path: '/external/letter/sign/$letterActionId'
      fullPath: '/external/letter/sign/$letterActionId'
      preLoaderRoute: typeof ExternalLetterSignLetterActionIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface LoginRouteChildren {
  LoginSplatRoute: typeof LoginSplatRoute
}

const LoginRouteChildren: LoginRouteChildren = {
  LoginSplatRoute: LoginSplatRoute,
}

const LoginRouteWithChildren = LoginRoute._addFileChildren(LoginRouteChildren)

interface PortalRouteChildren {
  PortalFormRoute: typeof PortalFormRoute
  PortalHomeRoute: typeof PortalHomeRoute
  PortalApplicantsCaseIdRoute: typeof PortalApplicantsCaseIdRoute
  PortalGroupGroupIdRoute: typeof PortalGroupGroupIdRoute
  PortalApplicantsIndexRoute: typeof PortalApplicantsIndexRoute
  PortalCompanyInfoIndexRoute: typeof PortalCompanyInfoIndexRoute
  PortalLetterReviewLetterActionIdRoute: typeof PortalLetterReviewLetterActionIdRoute
  PortalLetterSignLetterActionIdRoute: typeof PortalLetterSignLetterActionIdRoute
  PortalWorkspacesInviteInviteIdRoute: typeof PortalWorkspacesInviteInviteIdRoute
}

const PortalRouteChildren: PortalRouteChildren = {
  PortalFormRoute: PortalFormRoute,
  PortalHomeRoute: PortalHomeRoute,
  PortalApplicantsCaseIdRoute: PortalApplicantsCaseIdRoute,
  PortalGroupGroupIdRoute: PortalGroupGroupIdRoute,
  PortalApplicantsIndexRoute: PortalApplicantsIndexRoute,
  PortalCompanyInfoIndexRoute: PortalCompanyInfoIndexRoute,
  PortalLetterReviewLetterActionIdRoute: PortalLetterReviewLetterActionIdRoute,
  PortalLetterSignLetterActionIdRoute: PortalLetterSignLetterActionIdRoute,
  PortalWorkspacesInviteInviteIdRoute: PortalWorkspacesInviteInviteIdRoute,
}

const PortalRouteWithChildren =
  PortalRoute._addFileChildren(PortalRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof PortalRouteWithChildren
  '/not-found': typeof NotFoundRoute
  '/$': typeof LoginSplatRoute
  '/form': typeof PortalFormRoute
  '/home': typeof PortalHomeRoute
  '/applicants/$caseId': typeof PortalApplicantsCaseIdRoute
  '/group/$groupId': typeof PortalGroupGroupIdRoute
  '/applicants': typeof PortalApplicantsIndexRoute
  '/company-info': typeof PortalCompanyInfoIndexRoute
  '/letter/review/$letterActionId': typeof PortalLetterReviewLetterActionIdRoute
  '/letter/sign/$letterActionId': typeof PortalLetterSignLetterActionIdRoute
  '/workspaces/invite/$inviteId': typeof PortalWorkspacesInviteInviteIdRoute
  '/external/letter/review/$letterActionId': typeof ExternalLetterReviewLetterActionIdRoute
  '/external/letter/sign/$letterActionId': typeof ExternalLetterSignLetterActionIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof PortalRouteWithChildren
  '/not-found': typeof NotFoundRoute
  '/$': typeof LoginSplatRoute
  '/form': typeof PortalFormRoute
  '/home': typeof PortalHomeRoute
  '/applicants/$caseId': typeof PortalApplicantsCaseIdRoute
  '/group/$groupId': typeof PortalGroupGroupIdRoute
  '/applicants': typeof PortalApplicantsIndexRoute
  '/company-info': typeof PortalCompanyInfoIndexRoute
  '/letter/review/$letterActionId': typeof PortalLetterReviewLetterActionIdRoute
  '/letter/sign/$letterActionId': typeof PortalLetterSignLetterActionIdRoute
  '/workspaces/invite/$inviteId': typeof PortalWorkspacesInviteInviteIdRoute
  '/external/letter/review/$letterActionId': typeof ExternalLetterReviewLetterActionIdRoute
  '/external/letter/sign/$letterActionId': typeof ExternalLetterSignLetterActionIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_login': typeof LoginRouteWithChildren
  '/_portal': typeof PortalRouteWithChildren
  '/not-found': typeof NotFoundRoute
  '/_login/$': typeof LoginSplatRoute
  '/_portal/form': typeof PortalFormRoute
  '/_portal/home': typeof PortalHomeRoute
  '/_portal/applicants/$caseId': typeof PortalApplicantsCaseIdRoute
  '/_portal/group/$groupId': typeof PortalGroupGroupIdRoute
  '/_portal/applicants/': typeof PortalApplicantsIndexRoute
  '/_portal/company-info/': typeof PortalCompanyInfoIndexRoute
  '/_portal/letter/review/$letterActionId': typeof PortalLetterReviewLetterActionIdRoute
  '/_portal/letter/sign/$letterActionId': typeof PortalLetterSignLetterActionIdRoute
  '/_portal/workspaces/invite/$inviteId': typeof PortalWorkspacesInviteInviteIdRoute
  '/external/letter/review/$letterActionId': typeof ExternalLetterReviewLetterActionIdRoute
  '/external/letter/sign/$letterActionId': typeof ExternalLetterSignLetterActionIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/not-found'
    | '/$'
    | '/form'
    | '/home'
    | '/applicants/$caseId'
    | '/group/$groupId'
    | '/applicants'
    | '/company-info'
    | '/letter/review/$letterActionId'
    | '/letter/sign/$letterActionId'
    | '/workspaces/invite/$inviteId'
    | '/external/letter/review/$letterActionId'
    | '/external/letter/sign/$letterActionId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/not-found'
    | '/$'
    | '/form'
    | '/home'
    | '/applicants/$caseId'
    | '/group/$groupId'
    | '/applicants'
    | '/company-info'
    | '/letter/review/$letterActionId'
    | '/letter/sign/$letterActionId'
    | '/workspaces/invite/$inviteId'
    | '/external/letter/review/$letterActionId'
    | '/external/letter/sign/$letterActionId'
  id:
    | '__root__'
    | '/'
    | '/_login'
    | '/_portal'
    | '/not-found'
    | '/_login/$'
    | '/_portal/form'
    | '/_portal/home'
    | '/_portal/applicants/$caseId'
    | '/_portal/group/$groupId'
    | '/_portal/applicants/'
    | '/_portal/company-info/'
    | '/_portal/letter/review/$letterActionId'
    | '/_portal/letter/sign/$letterActionId'
    | '/_portal/workspaces/invite/$inviteId'
    | '/external/letter/review/$letterActionId'
    | '/external/letter/sign/$letterActionId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LoginRoute: typeof LoginRouteWithChildren
  PortalRoute: typeof PortalRouteWithChildren
  NotFoundRoute: typeof NotFoundRoute
  ExternalLetterReviewLetterActionIdRoute: typeof ExternalLetterReviewLetterActionIdRoute
  ExternalLetterSignLetterActionIdRoute: typeof ExternalLetterSignLetterActionIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LoginRoute: LoginRouteWithChildren,
  PortalRoute: PortalRouteWithChildren,
  NotFoundRoute: NotFoundRoute,
  ExternalLetterReviewLetterActionIdRoute:
    ExternalLetterReviewLetterActionIdRoute,
  ExternalLetterSignLetterActionIdRoute: ExternalLetterSignLetterActionIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_login",
        "/_portal",
        "/not-found",
        "/external/letter/review/$letterActionId",
        "/external/letter/sign/$letterActionId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_login": {
      "filePath": "_login.tsx",
      "children": [
        "/_login/$"
      ]
    },
    "/_portal": {
      "filePath": "_portal.tsx",
      "children": [
        "/_portal/form",
        "/_portal/home",
        "/_portal/applicants/$caseId",
        "/_portal/group/$groupId",
        "/_portal/applicants/",
        "/_portal/company-info/",
        "/_portal/letter/review/$letterActionId",
        "/_portal/letter/sign/$letterActionId",
        "/_portal/workspaces/invite/$inviteId"
      ]
    },
    "/not-found": {
      "filePath": "not-found.tsx"
    },
    "/_login/$": {
      "filePath": "_login/$.tsx",
      "parent": "/_login"
    },
    "/_portal/form": {
      "filePath": "_portal/form.tsx",
      "parent": "/_portal"
    },
    "/_portal/home": {
      "filePath": "_portal/home.tsx",
      "parent": "/_portal"
    },
    "/_portal/applicants/$caseId": {
      "filePath": "_portal/applicants/$caseId.tsx",
      "parent": "/_portal"
    },
    "/_portal/group/$groupId": {
      "filePath": "_portal/group/$groupId.tsx",
      "parent": "/_portal"
    },
    "/_portal/applicants/": {
      "filePath": "_portal/applicants/index.tsx",
      "parent": "/_portal"
    },
    "/_portal/company-info/": {
      "filePath": "_portal/company-info/index.tsx",
      "parent": "/_portal"
    },
    "/_portal/letter/review/$letterActionId": {
      "filePath": "_portal/letter/review/$letterActionId.tsx",
      "parent": "/_portal"
    },
    "/_portal/letter/sign/$letterActionId": {
      "filePath": "_portal/letter/sign/$letterActionId.tsx",
      "parent": "/_portal"
    },
    "/_portal/workspaces/invite/$inviteId": {
      "filePath": "_portal/workspaces/invite/$inviteId.tsx",
      "parent": "/_portal"
    },
    "/external/letter/review/$letterActionId": {
      "filePath": "external/letter/review/$letterActionId.tsx"
    },
    "/external/letter/sign/$letterActionId": {
      "filePath": "external/letter/sign/$letterActionId.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
