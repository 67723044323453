import { useCompany } from "@/providers/companyProvider";
import {
  ApplicantStatusGraphBadge,
  externalCaseStatuses,
} from "./applicantStatusBadge";
import { useResizeObserver } from "@/lib/hooks";
import { CaseStatus } from "@/providers/onboardingDataProvider";
import { useEffect, useRef, useState } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { ResultOf } from "@/lib/graphql";
import { getCompanyWorkspace } from "@/lib/queries";
import { cn } from "@/lib/cn";
import { Spinner } from "@radix-ui/themes";

const getCaseStats = (
  data: ResultOf<typeof getCompanyWorkspace>
): Record<CaseStatus, number> => {
  const stats: Record<CaseStatus, number> = {
    pending_documents: 0,
    in_progress: 0,
    in_final_review: 0,
    submitted: 0,
    in_review_uscis: 0,
    approved: 0,
    denied: 0,
    rfe: 0,
  };

  for (const x of data.getCompanyWorkspace.cases) {
    if (x.status == null) continue;
    if (stats[x.status] == null) continue;

    stats[x.status] += 1;
  }

  return stats;
};

const Graph = (props: { width: number; count: number; status: CaseStatus }) => {
  const { width, count, status } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [stripeCount, setStripeCount] = useState(1);

  const widthWithMin = Math.max(2, props.width);

  useEffect(() => {
    if (!containerRef.current) return;

    const calculateStripes = () => {
      const containerWidth =
        containerRef.current?.getBoundingClientRect().width ?? 0;
      // Each stripe unit is 3px (2px stripe + 1px gap)
      const newStripeCount = Math.floor(containerWidth / 3);
      setStripeCount(newStripeCount);
    };

    calculateStripes();

    const resizeObserver = new ResizeObserver(calculateStripes);
    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, [width]);

  const stripeColors = {
    pending_documents: "#B4CAFF",
    in_progress: "#888888",
    in_final_review: "#F089C1",
    submitted: "#CDCFD3",
    in_review_uscis: "#888888",
    approved: "#208011",
    denied: "#EE6262",
    rfe: "#F7B213",
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          className={cn("h-full relative flex flex-col justify-center pb-3")}
          ref={containerRef}
          style={{
            width: `${widthWithMin}%`,
            overflow: "hidden",
          }}
        >
          <div className="flex items-stretch gap-[1px] flex-nowrap items-bottom h-full transition-all duration-300">
            {widthWithMin > 12 && (
              <div
                className="absolute top-3 left-1.5"
                style={{
                  animation: `fadeIn 0.2s ease-out 0.2s backwards`,
                }}
              >
                <ApplicantStatusGraphBadge status={status} count={count} />
              </div>
            )}

            {[...Array(stripeCount)].map((_, i) => (
              <div
                key={i}
                className="animate-grow origin-bottom"
                style={{
                  width: "2px",
                  backgroundColor: stripeColors[status],
                  borderRadius: "1px",
                  height: i === 0 ? "32px" : "17px",
                  marginTop: "auto",
                  animation: `growFromBottom 0.2s ease-out 0.2s backwards`,
                }}
              />
            ))}
            <style>
              {`
                  @keyframes growFromBottom {
                    from {
                      transform: scaleY(0);
                      opacity: 0;
                    }
                    to {
                      transform: scaleY(1);
                      opacity: 1;
                    }
                  }
                  @keyframes fadeIn {
                      from {
                        opacity: 0;
                      }
                      to {
                        opacity: 1;
                        transform: translateY(0);
                      }
                    }`}
            </style>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent
        side="top"
        className="px-3 py-2 flex flex-row gap-1.5 text-xs rounded-md"
        sideOffset={-20}
        hideArrow
      >
        <span className="text-grey-400 stroke-grey-400">
          {externalCaseStatuses.find((x) => x.value === status)?.icon}
        </span>
        {externalCaseStatuses.find((x) => x.value === status)?.label ?? ""}
        <span className="text-grey-400">{count}</span>
      </TooltipContent>
    </Tooltip>
  );
};

const CaseStatsGraph = (props: { stats: Record<CaseStatus, number> }) => {
  const { stats } = props;
  const total = Object.values(stats).reduce((acc, val) => acc + val, 0);
  const containerRef = useRef<HTMLDivElement>(null);

  useResizeObserver(containerRef);

  return (
    <div className="w-full h-full flex flex-row gap-0.5" ref={containerRef}>
      {externalCaseStatuses.map((status) => (
        <Graph
          key={status.value}
          width={Math.floor((stats[status.value] / total) * 100)}
          count={stats[status.value]}
          status={status.value}
        />
      ))}
    </div>
  );
};

export const CompanyStats = () => {
  const { data } = useCompany();

  const stats = data == null ? undefined : getCaseStats(data);

  return (
    <div className="flex flex-row items-center px-8 py-8 border-b border-grey-600 gap-8 h-[137px]">
      <div className="flex flex-col gap-1 text-grey-100 w-1/6 h-full">
        <span className="text-sm font-semibold">Active applicants</span>
        <span className="text-[32px]">
          {data == null ? (
            <Spinner size={"3"} />
          ) : (
            data.getCompanyWorkspace.cases.filter(
              (x) => !["approved", "denied", "rfe"].includes(x.status)
            ).length
          )}
        </span>
      </div>
      <div className="w-full h-full ml-auto">
        {stats == null && (
          <div className="w-full h-full flex flex-row gap-1">
            {[...Array(10)].map((_, i) => (
              <div
                key={i}
                className="w-1/10 h-full animate-pulse bg-grey-500"
              />
            ))}
          </div>
        )}
        {stats != null && <CaseStatsGraph stats={stats} />}
      </div>
    </div>
  );
};
