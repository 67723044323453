import { useTutorialModalState } from "@/lib/hooks";
import { useState } from "react";
import { BaseTutorialPageProps, TutorialModal } from "../modal";

export const EmployerTutorialModal = () => {
  const employerModalState = useTutorialModalState((state) => state.employer);

  const [open, setOpen] = useState(
    employerModalState.modalShown ? false : true
  );

  const onCompleteTutorial = () => {
    employerModalState.setModalShown(true);
    setOpen(false);
  };

  const pages: BaseTutorialPageProps[] = [
    {
      topDescription: (
        <p>
          <span className="text-grey-200">
            Welcome to your employer workspace.
          </span>{" "}
          Here, you'll be able to view all applicants in the visa process, track
          their statuses, and sign the requested documentation for their
          applications.
        </p>
      ),
      title: "Request a new visa for your employee",
      image: <img src="/tutorial/employer/1.webp" />,
    },

    {
      topDescription: (
        <p>
          Start by completing your company information, which the Lighthouse
          team will need to draft each applicant's immigration forms.
        </p>
      ),
      title: "Provide info for your company",
      image: <img src="/tutorial/employer/2.webp" />,
    },
    {
      topDescription: (
        <p>
          For each candidate, provide information about their intended role and
          employment details.
        </p>
      ),
      title: "Provide info for your employee's application",
      image: <img src="/tutorial/employer/3.webp" />,
    },
  ];

  return (
    <TutorialModal
      pages={pages}
      open={open}
      onOpenChange={setOpen}
      onCompleteTutorial={onCompleteTutorial}
    />
  );
};
