import { OnboardingForm } from "@/components/form";
import { LetterSigner, LetterSigners } from "@/components/letterSigners";
import { SupportLetterPlaceholder } from "@/components/supportLetterPreview";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const ExpertLetter = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Support Letters">
      <span className="text-grey-300 text-[12px] mt-4 pr-12">
        This expert letter supports your visa application case strategy. The
        signer for this letter will speak directly to specific achievements or
        roles you've held that correspond to your visa application eligibility.
      </span>

      <LetterSigners
        value={(data.signers?.value ?? []) as LetterSigner[]}
        onChange={(signers) => doSetData("signers", signers)}
      />

      <SupportLetterPlaceholder />
    </OnboardingForm>
  );
};
