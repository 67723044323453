import { cn } from "@/lib/cn";
import { useSidebarStore } from "./sidebar";
import { Mark } from "./mark";
import { Spinner } from "@radix-ui/themes";
import { MarkRoundedSquareIcon } from "./icons/markRoundedSquare";

const SidebarOptionSkeleton = () => {
  return (
    <div
      className={cn(
        `flex flex-row items-center gap-2 p-3 h-[45px] rounded-[8px] bg-grey-700 w-[240px] animate-pulse`
      )}
    ></div>
  );
};

const SidebarSkeleton = () => {
  const open = useSidebarStore((state) => state.open);
  return (
    <div
      className={cn(
        "flex flex-col gap-2 h-full w-[256px]",
        !open && "w-[60px]"
      )}
    >
      <div className={cn("h-full flex flex-col", !open && "hidden")}>
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center">
          <Mark />
          <h1 className="font-serif font-[600] leading-[24px] text-base tracking-[-0.5%]">
            Lighthouse
          </h1>
        </div>

        <div className="flex flex-col gap-1 px-2 text-[14px] font-normal">
          <SidebarOptionSkeleton />
          <SidebarOptionSkeleton />
          <SidebarOptionSkeleton />
          <SidebarOptionSkeleton />
          <SidebarOptionSkeleton />
        </div>

        <div className="mt-auto flex flex-col h-1/6 px-6 pt-4 pb-6 gap-4 justify-end">
          <div className="w-full h-full bg-grey-700 rounded-md animate-pulse" />
        </div>
      </div>

      <div className={cn("h-full w-[60px] flex-col hidden", !open && "inline")}>
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center justify-center">
          <MarkRoundedSquareIcon key={`mark-${open}`} />
        </div>
      </div>
    </div>
  );
};

export const PageLoaderSkeleton = () => {
  return (
    <div className="w-screen h-screen max-w-screen flex flex-row bg-grey-800 pr-4">
      <SidebarSkeleton />
      <div className="w-full h-full py-4">
        <div className="rounded-[16px] bg-grey-700 w-full h-full flex flex-row justify-center items-center overflow-y-scroll border border-grey-600">
          <Spinner />
        </div>
      </div>
    </div>
  );
};
