import { CreateAdditionalWorkExperienceNodeOption } from "@/components/createAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm } from "@/components/form";
import { Checkbox, ListTextInput, TextArea } from "@/components/inputs";
import { URLReview } from "@/components/urlReview";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";
import { useEffect } from "react";

export const Press = () => {
  const { currentOnboardingNodeId, nodeStatus } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  useEffect(() => {
    if (data == null) return;
    if (
      data.publications == null ||
      data.publications.value == null ||
      data.publications.value.length === 0
    ) {
      if (data.hasOtherPublications?.value === true) return;
      doSetData("hasOtherPublications", true);
    }
  }, [data]);

  return (
    <OnboardingForm heading="Your work experience">
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        Press is a place for articles or news features from reputable sources
        that highlight your achievements or work. These should come from
        well-recognized and credible publications to support your application.
      </p>
      {(data.publications?.value ?? []).length > 0 && (
        <>
          <Field>
            <URLReview
              title="Good news! We've found some press articles about your work."
              description="Review and let us know if these are correct."
              links={data?.publications?.value ?? []}
              updateLinks={(links) => doSetData("publications", links)}
            />
          </Field>
          <Checkbox
            label="I have other profiles with publications I've authored that I'd like Lighthouse to review."
            checked={data.hasOtherPublications?.value ?? false}
            onCheckedChange={(value) => {
              doSetData("hasOtherPublications", value);
            }}
            disabled={nodeStatus === "submitted_for_review"}
            className="mr-12"
          />
        </>
      )}
      {data.hasOtherPublications?.value && (
        <ListTextInput
          value={data.otherPublications?.value}
          onValueChange={(value) => doSetData("otherPublications", value)}
          placeholder="https://forbes.com/article"
        />
      )}

      <Field
        id="additionalComments"
        label="Additional comments"
        description="If you have any additional comments, that you think will be useful for us working on this criteria, please add them here."
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="press"
          name="press"
          description="TODO: add copy"
        />
      )}
    </OnboardingForm>
  );
};
