import { UrlPreview } from "@/components/urlPreview";
import { cn } from "@/lib/cn";

const BlueArrow = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.91239" cy="1.42856" r="1.01786" fill="#1159F5" />
    <circle cx="6.94754" cy="1.42856" r="1.01786" fill="#1159F5" />
    <circle cx="4.43192" cy="3.96427" r="1.01786" fill="#1159F5" />
    <circle cx="9.46708" cy="3.96427" r="1.01786" fill="#1159F5" />
    <circle cx="6.94754" cy="6.49997" r="1.01786" fill="#1159F5" />
    <circle cx="11.9827" cy="6.49997" r="1.01786" fill="#1159F5" />
    <circle cx="4.43192" cy="9.03574" r="1.01786" fill="#1159F5" />
    <circle cx="9.46708" cy="9.03574" r="1.01786" fill="#1159F5" />
    <circle cx="1.91239" cy="11.5714" r="1.01786" fill="#1159F5" />
    <circle cx="6.94754" cy="11.5714" r="1.01786" fill="#1159F5" />
  </svg>
);

export const URLReview = (props: {
  title: string;
  description: string;
  links: {
    url: string;
    status: "pending" | "approved" | "rejected";
  }[];
  updateLinks: (
    links: { url: string; status: "pending" | "approved" | "rejected" }[]
  ) => void;
}) => {
  return (
    <div className="rounded-md pt-4 px-4 pb-3 gap-2 flex flex-col shadow-border bg-grey-700">
      <div className="flex flex-col gap-1">
        <div className="text-sm text-grey-200 flex flex-row gap-1 items-center justify-between pr-1">
          <p className="text-sm text-grey-200 text-nowrap text-ellipsis overflow-hidden">
            {props.title}
          </p>
          <BlueArrow />
        </div>
        <span className="text-xs text-grey-300">{props.description}</span>
      </div>

      <div className="flex flex-col gap-1">
        {props.links.map((link, index) => (
          <div
            key={index}
            className="rounded-md py-2 pl-2 pr-1.5 bg-grey-800 shadow-border flex flex-row items-center justify-between"
          >
            <div className="w-[70%]">
              <UrlPreview url={link.url} />
            </div>

            <div className="flex flex-row items-center gap-2 w-fit">
              <button
                className={cn(
                  "text-grey-300 text-sm px-2 py-0.5 rounded-sm text-nowrap",
                  link.status === "rejected" && "bg-grey-700 shadow-border",
                  "transition-all duration-150 ease-in-out"
                )}
                onClick={() => {
                  const newLinks = [...props.links];
                  newLinks[index] = { url: link.url, status: "rejected" };
                  props.updateLinks(newLinks);
                }}
              >
                Not relevant
              </button>

              <button
                onClick={() => {
                  const newLinks = [...props.links];
                  newLinks[index] = { url: link.url, status: "approved" };
                  props.updateLinks(newLinks);
                }}
                className={cn(
                  "text-positive text-sm px-2 py-0.5 rounded-sm",
                  link.status === "approved" &&
                    "bg-positive/10 shadow-positive",
                  "transition-all duration-150 ease-in-out"
                )}
              >
                Confirm
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
