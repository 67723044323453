import {
  CountryAddressInput,
  Input,
  PhoneNumber,
  PhoneNumberInput,
} from "@/components/inputs";
import { InputText } from "@/components/typography";
import { Spinner } from "@radix-ui/themes";
import { RadioSelect } from "@/components/radio";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { useOnboarding } from "@/providers/onboardingProvider";
import { TrashIcon } from "@/components/icons/trash";
import { cn } from "@/lib/cn";
import { Field, OnboardingForm } from "@/components/form";
import { FormValues } from "@/lib/formValues";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useEffect } from "react";

const Dependents = (props: {
  value?: string[];
  onValueChange: (value: string[]) => void;
}) => {
  const { value, onValueChange } = props;

  return (
    <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pl-3 mr-12">
      <div className="flex flex-col gap-2 pb-1 pl-1.5">
        <Field
          id="dependents"
          label="Full legal name of dependents"
          description="Name should be the same as on their passport"
        >
          <div className="flex flex-col gap-1 w-full">
            {(value == null || value.length === 0 ? [undefined] : value).map(
              (val, index) => (
                <div
                  key={index}
                  className="flex flex-row w-full group bg-white rounded-md has-[:focus]:shadow-border-active shadow-border transition-all duration-150"
                >
                  <Input
                    placeholder="Legal Name"
                    type="text"
                    value={val}
                    onValueChange={(newVal) => {
                      const newValArray = [...(value ?? [""])];
                      newValArray[index] = newVal;
                      onValueChange(newValArray);
                    }}
                    className="w-full shadow-none focus:shadow-none transition-none"
                  />
                  <button
                    className={cn(
                      "hidden opacity-0 group-hover:block group-hover:opacity-100 pr-2 transition-all duration-500",
                      index === 0 &&
                        (value ?? [undefined]).length === 1 &&
                        "group-hover:hidden"
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      const newValArray = [...(value ?? [""])];
                      newValArray.splice(index, 1);
                      onValueChange(newValArray);
                    }}
                  >
                    <TrashIcon className="fill-grey-100" />
                  </button>
                </div>
              )
            )}
          </div>
        </Field>

        <button
          className="flex flex-row gap-[6px] py-1.5 items-center w-fit"
          onClick={() => {
            const newValArray = [...(value ?? [""])];
            newValArray.push("");
            onValueChange(newValArray);
          }}
        >
          <PlusCircledIcon className="text-blue" width={12} height={12} />
          <InputText className="text-blue text-[12px]">
            Add next person
          </InputText>
        </button>
      </div>
    </div>
  );
};

const InnerForm = (props: {
  defaultData: FormValues;
  doSetData: (
    key: string,
    value: string | boolean | Record<string, string> | string[]
  ) => void;
}) => {
  const { defaultData: data, doSetData } = props;

  const currentAddress = (data.currentAddress?.value ?? {}) as Record<
    string,
    string
  >;

  const usAddress = (data.usAddress?.value ?? {}) as Record<string, string>;
  const phoneNumber = data.phoneNumber?.value as PhoneNumber | undefined;

  useEffect(() => {
    if (data.usAddress?.value == null) {
      doSetData("usAddress", {
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "United States",
      });
    }
  }, []);

  return (
    <>
      <Field
        id="fullLegalName"
        label="Full legal name"
        description="Name should be the same as on your passport"
      >
        <Input
          value={data.fullLegalName?.value as string}
          placeholder="Enter your full legal name"
          onValueChange={(val) => doSetData("fullLegalName", val)}
        />
      </Field>
      <Field id="pronouns" label="How would you like to be referred to?">
        <RadioSelect
          value={data.pronouns?.value as string}
          options={[
            { value: "she/her", label: "She/Her" },
            { value: "he/him", label: "He/Him" },
            { value: "they/them", label: "They/Them" },
            { value: "other", label: "Other" },
          ]}
          onChange={(val) => doSetData("pronouns", val)}
        />
      </Field>
      <Field id="email" label="Email address">
        <Input
          placeholder="john@example.com"
          type="email"
          value={data.email?.value as string}
          onValueChange={(val) => doSetData("email", val)}
        />
      </Field>
      <Field
        id="phoneNumber"
        label="Phone Number"
        description="If you would like to receive timely updates and notifications regarding the status of your application, we require your phone number."
      >
        <PhoneNumberInput
          value={phoneNumber}
          onValueChange={(val) => doSetData("phoneNumber", val)}
          placeholder="Enter your phone number"
        />
      </Field>

      <Field
        id="ssn"
        label="Social Security Number"
        description="We need your Social Security Number to include on your government forms."
      >
        <Input
          placeholder="123-45-6789"
          type="text"
          value={data.ssn?.value as string}
          onValueChange={(val) => doSetData("ssn", val)}
        />
      </Field>

      <div className="pr-12">
        <div className="flex flex-col bg-grey-700 rounded-lg pt-1 pb-4 pl-3">
          <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
            {/* <InputText className="text-grey-300 text-[12px] pl-1">
              What is your current foreign address?
            </InputText> */}

            <div id="field-currentAddress" className="flex flex-col gap-2">
              <Field
                label="What is your current foreign address?"
                className="pr-4"
                description="Please provide a non-US home address. This is critical for your immigration forms."
              >
                <Input
                  placeholder="123 Main St"
                  type="text"
                  value={currentAddress.street ?? ""}
                  onValueChange={(val) =>
                    doSetData("currentAddress", {
                      ...currentAddress,
                      street: val,
                    })
                  }
                  autoComplete={"street-address"}
                />
              </Field>
              <Field label="City" className="pr-4">
                <Input
                  placeholder="San Francisco"
                  type="text"
                  value={currentAddress.city ?? ""}
                  onValueChange={(val) =>
                    doSetData("currentAddress", {
                      ...currentAddress,
                      city: val,
                    })
                  }
                  autoComplete={"address-level2"}
                />
              </Field>
              <div className="flex flex-row gap-2 w-full">
                <Field label="State" className="pr-4">
                  <Input
                    placeholder="California"
                    type="text"
                    value={currentAddress.state ?? ""}
                    onValueChange={(val) =>
                      doSetData("currentAddress", {
                        ...currentAddress,
                        state: val,
                      })
                    }
                    autoComplete={"address-level1"}
                  />
                </Field>
                <Field label="ZIP / Postal Code" className="pr-4">
                  <Input
                    placeholder="94102"
                    type="text"
                    value={currentAddress.zip ?? ""}
                    onValueChange={(val) =>
                      doSetData("currentAddress", {
                        ...currentAddress,
                        zip: val,
                      })
                    }
                    autoComplete={"postal-code"}
                  />
                </Field>
              </div>
              <Field label="Country" className="pr-4">
                <CountryAddressInput
                  value={currentAddress.country}
                  onValueChange={(val) =>
                    doSetData("currentAddress", {
                      ...currentAddress,
                      country: val as string,
                    })
                  }
                />
              </Field>
            </div>
          </div>
        </div>
      </div>

      <div className="pr-12">
        <div className="flex flex-col bg-grey-700 rounded-lg pt-1 pb-4 pl-3">
          <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
            {/* <InputText className="text-grey-300 text-[12px] pl-1">
              If you live in the U.S., what is your current U.S. home address?
            </InputText> */}

            <div id="field-usAddress" className="flex flex-col gap-2">
              <Field
                label="If you live in the U.S., what is your current U.S. home address?"
                className="pr-4"
                description="Only provide the address if you live in the U.S."
              >
                <Input
                  placeholder="123 Main St"
                  type="text"
                  value={usAddress.street ?? ""}
                  onValueChange={(val) =>
                    doSetData("usAddress", {
                      ...usAddress,
                      street: val,
                    })
                  }
                  autoComplete={"street-address"}
                />
              </Field>
              <Field label="City" className="pr-4">
                <Input
                  placeholder="San Francisco"
                  type="text"
                  value={usAddress.city ?? ""}
                  onValueChange={(val) =>
                    doSetData("usAddress", {
                      ...usAddress,
                      city: val,
                    })
                  }
                  autoComplete={"address-level2"}
                />
              </Field>
              <div className="flex flex-row gap-2 w-full">
                <Field label="State" className="pr-4">
                  <Input
                    placeholder="California"
                    type="text"
                    value={usAddress.state ?? ""}
                    onValueChange={(val) =>
                      doSetData("usAddress", {
                        ...usAddress,
                        state: val,
                      })
                    }
                    autoComplete={"address-level1"}
                  />
                </Field>
                <Field label="ZIP / Postal Code" className="pr-4">
                  <Input
                    placeholder="94102"
                    type="text"
                    value={usAddress.zip ?? ""}
                    onValueChange={(val) =>
                      doSetData("usAddress", {
                        ...usAddress,
                        zip: val,
                      })
                    }
                    autoComplete={"postal-code"}
                  />
                </Field>
              </div>
              <Field label="Country" className="pr-4">
                <CountryAddressInput
                  value={usAddress.country}
                  onValueChange={(val) =>
                    doSetData("usAddress", {
                      ...usAddress,
                      country: val as string,
                    })
                  }
                  disabled
                />
              </Field>
            </div>
          </div>
        </div>
      </div>

      <Field
        id="hasDependents"
        label="Do you have any dependents (spouse, children) that will require a dependent visa application?"
      >
        <RadioSelect
          value={data.hasDependents?.value as boolean}
          onChange={(val) => doSetData("hasDependents", val)}
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
        />
      </Field>
      {data.hasDependents?.value === true && (
        <Dependents
          value={data.dependents?.value as string[]}
          onValueChange={(val) => doSetData("dependents", val)}
        />
      )}
    </>
  );
};

export const PersonalDetails = () => {
  const { currentOnboardingNodeId } = useOnboarding();

  const { data, fetching, error, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Your personal details and visa background">
      {fetching && data == null && <Spinner />}

      {error != null && (
        <div className="text-sm text-negative">
          Error loading personal details, please contact
          support@lighthousehq.com for assistance.
        </div>
      )}

      {data != null && !fetching && (
        <InnerForm defaultData={data as FormValues} doSetData={doSetData} />
      )}
    </OnboardingForm>
  );
};
