import { OnboardingForm } from "@/components/form";

import { LetterSigner, LetterSigners } from "@/components/letterSigners";

import { SupportLetterPlaceholder } from "@/components/supportLetterPreview";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const AdvisoryOpinionLetter = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Support Letters">
      <div className="text-grey-300 text-xs mt-3 pr-12 flex flex-col gap-2 mb-1">
        <p>
          The Advisory Opinion letter is an expert letter in support of an O-1A
          application. The signer for this letter should be a US-based
          individual in your industry who can vouch for your overall
          professional experience and expertise in your shared field. In
          essence, this signer is lending his or her reputation in the industry
          to your application and achievements. <br />
        </p>

        <p>Importantly:</p>

        <ul className="list-disc pl-4">
          <li>
            This signer should be US-based to show strong US-based professional
            expertise. However, this signer does not have to be a US citizen.
          </li>
          <li>
            This signer should not have a financial stake in your visa's
            intended employer to minimize what USCIS may see as conflict of
            interest.
          </li>
          <li>
            This signer does not have to have direct experience working with
            you.
          </li>
        </ul>
      </div>

      <LetterSigners
        value={(data.signers?.value ?? []) as LetterSigner[]}
        onChange={(signers) => doSetData("signers", signers)}
      />
      <SupportLetterPlaceholder />
    </OnboardingForm>
  );
};
