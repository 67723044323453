import { CrossCircledIcon } from "@radix-ui/react-icons";
import { toast as hotToast, Toast } from "react-hot-toast";

const CustomToast = (props: {
  header: string;
  icon: React.ReactNode;
  message: string;
  t: Toast;
}) => {
  const { header, icon, message, t } = props;

  return (
    <div className="bg-grey-200 rounded-xl py-3 px-4 flex flex-col gap-2 w-[392px] z-90">
      <div className="w-full flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          {icon}
          <span className="text-[14px] text-grey-800">{header}</span>
        </div>

        <div className="flex flex-row items-center gap-2">
          <button onClick={() => hotToast.dismiss(t.id)}>
            <CrossCircledIcon className="w-[16px] h-[16px] text-grey-400" />
          </button>
        </div>
      </div>
      <p className="text-[14px] text-grey-500">{message}</p>
    </div>
  );
};

export const customToast = (
  header: string,
  icon: React.ReactNode,
  message: string
) =>
  hotToast.custom((t: Toast) => (
    <CustomToast header={header} icon={icon} message={message} t={t} />
  ));
