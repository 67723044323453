import { Heading } from "@radix-ui/themes";
import { ButtonText, LabelText } from "./typography";
import { OnboardingGroupTimeline } from "./timeline";
import { cn } from "@/lib/cn";
import { Button } from "./button";
import { ArrowRightIcon, CheckCircledIcon } from "@radix-ui/react-icons";
import { useOnboarding } from "@/providers/onboardingProvider";
import {
  Tooltip,
  TooltipContent,
  TooltipIcon,
  TooltipTrigger,
} from "./tooltip";
import {
  getOnboardingNodeFiles,
  submitNodeForReview,
  subscribeToOnboardingNodeFileChange,
  unlinkFileMutation,
  useOnboardingNodeFileUploadMutation,
} from "@/lib/queries";
import { FileUploadArea } from "./fileUploadArea";
import { useMutation, useQuery, useSubscription } from "urql";
import { WarningIcon } from "./icons/warning";
import {
  useWorkExperienceModalStore,
  WorkExperienceIntroModal,
} from "./onboarding/modals/workExperience";
import {
  SupportLettersIntroModal,
  useSupportLettersModalStore,
} from "./onboarding/modals/supportLetters";
import { useDataField } from "@/lib/hooks";

import { customToast } from "./toast";
import { LockIcon as LockIconPink } from "./icons/sidebar";
import { LockIcon } from "./icons/lock";
import { useState } from "react";
import { Modal } from "./modal";

export const FormTitleInfoIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11Z"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.125 6.125C6.22446 6.125 6.31984 6.16451 6.39016 6.23484C6.46049 6.30516 6.5 6.40054 6.5 6.5V8.375C6.5 8.47446 6.53951 8.56984 6.60983 8.64017C6.68016 8.71049 6.77554 8.75 6.875 8.75"
      stroke="#666666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 4.4375C6.375 4.47202 6.34702 4.5 6.3125 4.5C6.27798 4.5 6.25 4.47202 6.25 4.4375C6.25 4.40298 6.27798 4.375 6.3125 4.375C6.34702 4.375 6.375 4.40298 6.375 4.4375Z"
      fill="#666666"
      stroke="#666666"
    />
  </svg>
);

export const Field = (props: {
  id?: string;
  label?: string;
  description?: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  className?: string;
  rejectionBorderClassName?: string;
}) => {
  const { rejectionReason, status, disabled } = useDataField(props.id ?? "");

  return (
    <div
      className={cn(
        "flex flex-col gap-[1px] w-full",
        props.tooltip == null ? "pr-12" : "pr-0",
        props.className
      )}
      id={`field-${props.id}`}
    >
      {(props.label != null || props.description != null) && (
        <div className="py-3 pr-8 pl-1.5 gap-[4px] flex flex-col">
          {props.label != null && (
            <LabelText
              className={cn(
                "flex flex-row gap-1 items-center text-grey-300 text-sm"
              )}
              as="span"
            >
              {props.label}
            </LabelText>
          )}

          {props.description && (
            <LabelText as="span" className="text-grey-400 text-[12px]">
              {props.description}
            </LabelText>
          )}
        </div>
      )}

      <div
        className={cn(
          "flex flex-row items-center w-full",
          props.tooltip == null &&
            status === "rejected" &&
            "shadow-rejected-field rounded-md"
        )}
      >
        <div
          className={cn(
            "w-full",
            props.tooltip != null &&
              status === "rejected" &&
              "shadow-rejected-field rounded-lg",
            status === "rejected" && props.rejectionBorderClassName,

            disabled && "pointer-events-none opacity-50"
          )}
          tabIndex={-1}
        >
          {props.children}
        </div>

        {props.tooltip != null && (
          <div className="h-full flex flex-row items-center justify-center w-12">
            <Tooltip>
              <TooltipTrigger asChild>
                <TooltipIcon className="h-fit" />
              </TooltipTrigger>
              <TooltipContent
                hideArrow
                sideOffset={5}
                className="max-w-[342px]"
              >
                {props.tooltip}
              </TooltipContent>
            </Tooltip>
          </div>
        )}
      </div>

      {status == "rejected" && (
        <div className="flex flex-col gap-1 text-sm bg-negative/20 rounded-md text-negative p-2 mt-2">
          <span className="flex flex-row gap-2 items-center font-[525]">
            <WarningIcon />
            Your response has been rejected
          </span>

          <span className="text-negative">
            {rejectionReason ??
              "Please upload another document, or correct the errors on this field."}
          </span>
        </div>
      )}
    </div>
  );
};

export const FormCard = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "shadow-form bg-white rounded-xl w-full max-h-full pt-4 pl-12 pb-8 flex flex-col gap-3 overflow-scroll",
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

const showSubmitToast = () =>
  customToast(
    "This step has been submitted",
    <CheckCircledIcon className="w-[16px] h-[16px] text-positive" />,
    "The Lighthouse team will review it and either add it to your visa application or inform you if any changes are needed."
  );

const showErrorToast = () =>
  customToast(
    "Failed to submit section",
    <WarningIcon />,
    "Failed to submit section, please reach out to support@lighthousehq.com for assistance."
  );

export const SubmitModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onboardingId: number;
  currentNodeId: number;
}) => {
  const { currentNodeId, setOpen } = props;
  const submitNodeForReviewMutation = useMutation(submitNodeForReview)[1];

  const [loading, setLoading] = useState(false);

  const doSubmitGroupForReview = async () => {
    if (loading) return;
    setLoading(true);

    const { error } = await submitNodeForReviewMutation({
      nodeId: currentNodeId,
    });

    if (error != null) {
      console.error(error);
      setLoading(false);
      setOpen(false);
      showErrorToast();

      return;
    }

    setLoading(false);
    setOpen(false);
    showSubmitToast();
  };

  return (
    <Modal
      open={props.open}
      onOpenChange={props.setOpen}
      title="Submit section"
      fitContent
      contentClassName="w-[514px] z-5"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-100">
          You haven't completed all the required fields for this section. Are
          you sure you want to submit?
        </p>

        <div className="flex flex-row items-center mt-2">
          <Button
            variant="secondary"
            onClick={() => props.setOpen(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={doSubmitGroupForReview}
            className="ml-auto"
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const OnboardingForm = (props: {
  heading: string;
  children: React.ReactNode;
  hideControls?: boolean;
  onTitleInfoClick?: () => void;
  className?: string;
}) => {
  const {
    nextStep,
    previousStep,
    canGoBack,
    currentOnboardingGroup,
    canGoForward,
    currentOnboardingNodeId,
    progress,
    nodeStatus,
    onboardingId,
  } = useOnboarding();

  const submitNodeForReviewMutation = useMutation(submitNodeForReview)[1];

  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const toggleWorkExperienceModal = useWorkExperienceModalStore(
    (state) => state.setOpen
  );
  const workExperienceModalOpen = useWorkExperienceModalStore(
    (state) => state.open
  );

  const toggleSupportLettersModal = useSupportLettersModalStore(
    (state) => state.setOpen
  );
  const supportLettersModalOpen = useSupportLettersModalStore(
    (state) => state.open
  );

  const toggleModal = () => {
    if (currentOnboardingGroup === "work_experience") {
      return toggleWorkExperienceModal(true);
    }

    if (currentOnboardingGroup === "support_letters") {
      return toggleSupportLettersModal(true);
    }
  };

  const doNextStep = () => {
    nextStep();
  };

  const isModalOpen =
    (currentOnboardingGroup === "work_experience" && workExperienceModalOpen) ||
    (currentOnboardingGroup === "support_letters" && supportLettersModalOpen);

  const doSubmitNode = async () => {
    if (currentOnboardingGroup == null) return;
    if (currentOnboardingNodeId == null) return;

    const currentNodeProgress =
      progress[currentOnboardingGroup]?.nodeProgress[currentOnboardingNodeId];
    if (currentNodeProgress == null) return;

    if (
      currentNodeProgress.completedFields >=
      0.8 * currentNodeProgress.totalFields
    ) {
      const { error } = await submitNodeForReviewMutation({
        nodeId: currentOnboardingNodeId,
      });

      if (error != null) {
        console.error(error);
        showErrorToast();
      } else {
        showSubmitToast();
      }
      return;
    } else {
      setSubmitModalOpen(true);
    }
  };

  return (
    <div
      className={cn(
        "w-full h-full rounded-xl bg-cover bg-center flex flex-col items-center py-16 overflow-hidden",
        currentOnboardingGroup == "about_you" &&
          "bg-[url(/backgrounds/about-you.webp)] bg-center",
        currentOnboardingGroup == "work_experience" &&
          "bg-[url(/backgrounds/work-experience.webp)] bg-center",
        currentOnboardingGroup == "support_letters" &&
          "bg-[url(/backgrounds/support-letters.webp)] bg-bottom"
      )}
      key={`onboarding-form-${currentOnboardingNodeId}`}
    >
      {currentOnboardingGroup === "work_experience" && (
        <WorkExperienceIntroModal />
      )}

      {currentOnboardingGroup === "support_letters" && (
        <SupportLettersIntroModal />
      )}

      {currentOnboardingNodeId != null && (
        <SubmitModal
          open={submitModalOpen}
          setOpen={setSubmitModalOpen}
          onboardingId={onboardingId}
          currentNodeId={currentOnboardingNodeId}
        />
      )}

      {!isModalOpen && (
        <div className="flex flex-col gap-5 w-[55%] h-full">
          <div className="h-[157px] w-full flex flex-col pt-6 pb-1 pl-12 pr-6 gap-6 bg-[#F7F7F7] bg-opacity-[64%] rounded-xl flex-shrink-0 sm:h-fit backdrop-blur-md">
            <div className="flex items-center w-full gap-2">
              <Heading>{props.heading}</Heading>
              {nodeStatus === "submitted_for_review" && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="p-1 bg-black bg-opacity-[16%] rounded-md">
                      <LockIcon />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="top">
                    <div className="flex flex-col gap-1 py-1.5 px-3 w-[251px] text-grey-500">
                      You will not be able to edit this section until the
                      Lighthouse team has reviewed your submission.
                    </div>
                  </TooltipContent>
                </Tooltip>
              )}

              <button onClick={toggleModal} className="ml-auto">
                <FormTitleInfoIcon />
              </button>
            </div>

            <div className="mt-auto">
              <OnboardingGroupTimeline
                allowCreate={
                  currentOnboardingGroup === "work_experience" &&
                  nodeStatus !== "submitted_for_review"
                }
              />
            </div>
          </div>

          <FormCard className={props.className}>
            {props.children}

            {!props.hideControls && (
              <div className="mt-3 flex flex-row justify-between items-center pr-12">
                {canGoBack && (
                  <Button
                    variant="secondary"
                    onClick={previousStep}
                    disabled={!canGoBack}
                  >
                    <ButtonText>Previous</ButtonText>
                  </Button>
                )}

                <div className="flex flex-row items-center gap-2 ml-auto">
                  {nodeStatus !== "submitted_for_review" && (
                    <Button variant="primary" onClick={doSubmitNode}>
                      <div className="flex flex-row items-center gap-2">
                        <ButtonText>Submit for Review</ButtonText>
                      </div>
                    </Button>
                  )}

                  {nodeStatus === "submitted_for_review" && (
                    <Button
                      variant="primary"
                      disabled
                      className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
                    >
                      <div className="flex flex-row items-center gap-2">
                        <LockIconPink />
                        <ButtonText>Submitted</ButtonText>
                      </div>
                    </Button>
                  )}
                  {canGoForward && (
                    <Button
                      variant="secondary"
                      onClick={doNextStep}
                      disabled={!canGoForward}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <ButtonText>Next</ButtonText>
                        <ArrowRightIcon />
                      </div>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </FormCard>
        </div>
      )}
    </div>
  );
};

export const SubkeyUploadArea = (props: {
  onboardingNodeId: number;
  subKey: string;
}) => {
  const { onboardingNodeId, subKey } = props;

  const [{ data }, refetch] = useQuery({
    query: getOnboardingNodeFiles,
    variables: { id: onboardingNodeId, subKey: subKey },
    pause: onboardingNodeId == null,
    requestPolicy: "cache-and-network",
  });

  useSubscription(
    {
      query: subscribeToOnboardingNodeFileChange,
      variables: { id: onboardingNodeId },
    },
    () => refetch({ requestPolicy: "network-only" })
  );

  const uploadFiles = useOnboardingNodeFileUploadMutation();
  const removeMutation = useMutation(unlinkFileMutation)[1];

  const onUpload = async (files: File[]) => {
    if (files.length == 0) return;
    await uploadFiles(files, onboardingNodeId, subKey);
    await refetch({ requestPolicy: "network-only" });
  };

  const doRemove = async (fileId: number) => {
    await removeMutation({
      input: {
        fileId: fileId,
        linkType: "onboarding-node",
        linkRecordId: onboardingNodeId,
        linkSubKey: subKey,
      },
    });

    await refetch({ requestPolicy: "network-only" });
  };

  return (
    <FileUploadArea
      onUpload={onUpload}
      files={(data?.onboardingNodeFiles ?? []).map((file) => ({
        id: file.id,
        name: file.name,
        mimeType: file.mimeType,
        size: file.size ?? undefined,
        thumbnailUrl: file.thumbnailUrl ?? undefined,
      }))}
      onRemove={doRemove}
      key={`upload-area-${onboardingNodeId}-${subKey}`}
      multiple
    />
  );
};
