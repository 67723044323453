import {
  approveLetterAction,
  getCompanyMembers,
  getFollowUpAction,
  getLetterActionById,
  requestLetterActionChanges,
} from "@/lib/queries";
import {
  EditorContextProvider,
  useEditorContext,
} from "@/providers/editorProvider";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { useClient, useMutation, useQuery } from "urql";
import { TextEditorComments } from "@/components/textEditorComments";
import { TextEditor } from "@/components/textEditor";
import { HocuspocusProvider } from "@hocuspocus/provider";
import * as Y from "yjs";
import { useCallback, useEffect, useState } from "react";
import { useTutorialModalState, useUserContext } from "@/lib/hooks";
import { ResultOf } from "gql.tada";
import { Spinner } from "@radix-ui/themes";
import { Mark } from "@/components/mark";
import {
  ArrowRightIcon,
  CheckCircledIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";
import { Modal } from "@/components/modal";
import { Button } from "@/components/button";
import { FaRegCircleCheck, FaRegCommentDots } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { LetterReviewsTable } from "../../../../components/letterReviewsTable";
import { cn } from "@/lib/cn";
import { LoadingOverlay } from "@/components/loadingOverlay";
import { LetterActionNotFoundPage } from "@/components/letterActionNotFoundPage";
import { SupportLettersTutorialModal } from "@/components/tutorials/supportLettersTutorial";
import { CommentYMap } from "@lighthouse/editor";

const SuccessModal = (props: {
  open: boolean;
  isBeneficiary: boolean;
  letterId: number;
  setOpen: (value: boolean) => void;
  signatureLetterActionId?: number;
}) => {
  const { open, setOpen, isBeneficiary, letterId, signatureLetterActionId } =
    props;

  const nav = useNavigate();

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Letter Approved!"
      fitContent
      contentClassName={cn(
        "w-[550px] z-5 py-2 px-4",
        isBeneficiary &&
          signatureLetterActionId == null &&
          "w-[800px] max-w-[800px]"
      )}
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        {signatureLetterActionId == null && isBeneficiary ? (
          <p className="text-sm text-grey-300">
            This letter has been successfully approved. Now it's time to send
            the letter to any remaining reviewers.
          </p>
        ) : (
          <p className="text-sm text-grey-300">
            This letter has been successfully approved. Other reviews are still
            in progress. You will be notified when your signature is needed. You
            can close this window.
          </p>
        )}

        {signatureLetterActionId != null && (
          <p className="text-sm text-grey-300">
            This letter has been successfully approved, and is ready for your
            signature. Click the button below to sign the letter.
          </p>
        )}

        {signatureLetterActionId == null && isBeneficiary && (
          <LetterReviewsTable letterId={letterId} />
        )}
      </div>

      <div className="flex flex-row justify-end">
        {signatureLetterActionId == null && (
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() => setOpen(false)}
          >
            Got it
          </Button>
        )}

        {signatureLetterActionId != null && (
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() =>
              nav({
                to: "/letter/sign/$letterActionId",
                params: { letterActionId: signatureLetterActionId.toString() },
                search: {
                  reviewed: true,
                },
              })
            }
          >
            Sign Letter
          </Button>
        )}
      </div>
    </Modal>
  );
};

export const FailiureModal = (props: {
  open: boolean;
  setOpen: (alue: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Oops! Something Went Wrong"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Looks like something went wrong. Please try again.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export const ConfirmApproveModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onApprove: () => void;
}) => {
  const { open, setOpen, onApprove } = props;

  const [disabled, setDisabled] = useState(false);

  const approve = () => {
    if (disabled) return;

    setDisabled(true);
    setOpen(false);
    onApprove();
  };

  return (
    <Modal
      open={open}
      onOpenChange={() => setOpen(false)}
      title="Confirm Approval"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          You have suggested changes to this letter. Are you sure you want to
          approve it?
        </p>
      </div>

      <div className="flex flex-row justify-end gap-2">
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={disabled}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={approve}
        >
          Approve
        </Button>
      </div>
    </Modal>
  );
};

export const hasUserSubmittedComment = (
  provider: HocuspocusProvider | null,
  userId: string
) => {
  if (userId == null || provider == null) return false;

  const comments = provider.document.getArray(
    "comments"
  ) as Y.Array<CommentYMap>;

  for (let i = 0; i < provider.document.getArray("comments").length; i++) {
    const comment = comments.get(i);

    if (
      comment.get("userId") === userId &&
      comment.get("completed") === true &&
      comment.get("resolved") === false
    ) {
      return true;
    }
  }

  return false;
};

const SubmitReviewButton = (props: {
  letterActionId: number;
  letterId: number;
  hasSuggestedChanges: boolean;
  isBeneficiary: boolean;
  status: string;
  setStatus: (value: string) => void;
}) => {
  const {
    letterActionId,
    letterId,
    hasSuggestedChanges,
    status,
    isBeneficiary,
    setStatus,
  } = props;

  const client = useClient();

  const [loading, setLoading] = useState(false);
  const [confirmApprovalModalOpen, setConfirmApprovalModalOpen] =
    useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [signatureLetterActionId, setSignatureLetterActionId] =
    useState<number>();

  const { provider, editor } = useEditorContext();

  const approveLetterActionMutation = useMutation(approveLetterAction)[1];

  const approve = async () => {
    if (loading || provider?.document == null || editor == null) return;
    setLoading(true);

    // const comments = provider.document.getArray(
    //   "comments"
    // ) as Y.Array<CommentYMap>;

    // for (let i = 0; i < provider.document.getArray("comments").length; i++) {
    //   const comment = comments.get(i);

    //   // if (comment.get("userId") === userId) {
    //   //   comment.set("resolved", true);
    //   //   editor.commands.setCommentHighlightResolvedById(
    //   //     comment.get("id") as string,
    //   //     true
    //   //   );
    //   // }
    // }

    const res = await approveLetterActionMutation({
      letterActionId: letterActionId,
    });

    if (res.error) {
      setLoading(false);
      setFailureModalOpen(true);
      return;
    }

    const followUpRes = await client.query(getFollowUpAction, {
      letterActionId: letterActionId,
    });

    setLoading(false);

    if (followUpRes.data?.getFollowUpAction != null)
      setSignatureLetterActionId(followUpRes.data.getFollowUpAction);

    setStatus("approved");
    setSuccessModalOpen(true);
  };

  return (
    <>
      <SuccessModal
        open={successModalOpen}
        setOpen={setSuccessModalOpen}
        letterId={letterId}
        isBeneficiary={isBeneficiary}
        signatureLetterActionId={signatureLetterActionId}
      />

      <LoadingOverlay isLoading={loading} />

      <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />

      <ConfirmApproveModal
        open={confirmApprovalModalOpen}
        setOpen={setConfirmApprovalModalOpen}
        onApprove={approve}
      />

      {status === "approved" && (
        <Button
          variant="primary"
          disabled
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Letter Approved
        </Button>
      )}

      {status !== "approved" && hasSuggestedChanges && (
        <Button
          onClick={() => setConfirmApprovalModalOpen(true)}
          variant="primary"
          disabled={loading}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Approve Letter
        </Button>
      )}

      {status !== "approved" && !hasSuggestedChanges && (
        <Button
          onClick={approve}
          variant="primary"
          disabled={loading}
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
        >
          Approve Letter
        </Button>
      )}
    </>
  );
};

const getCommentUserIdWhitelist = (
  data: ResultOf<typeof getLetterActionById>,
  companyMembers?: ResultOf<typeof getCompanyMembers>
) => {
  if (data.getLetterAction.recipientType === "beneficiary") {
    if (data.getLetterAction.beneficiaryId == null) return [];
    return [data.getLetterAction.beneficiaryId.toString()];
  }

  if (data.getLetterAction.recipientType === "company") {
    return (
      companyMembers?.getCompanyWorkspace?.members?.members?.map((el) =>
        el.id.toString()
      ) ?? []
    );
  }

  return [];
};

export const InformationModal = (props: {
  isBeneficiary: boolean;
  beneficiaryName: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, isBeneficiary, beneficiaryName, setOpen } = props;

  const formattedBeneficiaryName =
    beneficiaryName[beneficiaryName.length - 1] == "s"
      ? beneficiaryName + "'"
      : beneficiaryName + "'s";

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Reviewing a letter"
      fitContent
      contentClassName="w-[650px] max-w-[80%] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        {!isBeneficiary && (
          <p className="text-sm text-grey-300">
            Your review is needed regarding this letter in support of{" "}
            {formattedBeneficiaryName} upcoming work visa application.
          </p>
        )}

        <p className="text-sm text-grey-300">
          Please review carefully the facts presented in this letter. This
          letter will be submitted to USCIS, the government agency responsible
          for citizenship and immigration.
        </p>

        <p className="text-sm text-grey-200">A few key points about USCIS:</p>

        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-start justify-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              They prefer a grandiose tone in these letters. Although this may
              seem unusual, it is standard practice and enhances the likelihood
              of approval.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Since USCIS may not be familiar with the specific industry, it is
              crucial to clearly outline the applicant's achievements and
              expertise in related or adjacent fields.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Processing times can be lengthy and the stakes are high. Prompt
              review is highly recommended.
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() => setOpen(false)}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const CommentHintPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <div className="flex flex-row items-center bg-grey-200 px-4 py-3 gap-2.5 absolute rounded-lg top-4 shadow-action-button">
        <FaRegCommentDots color="#3a87c2" />

        <span className="text-sm text-grey-800">
          Highlight any text to leave a comment and propose changes
        </span>

        <button onClick={() => setOpen(false)}>
          <FiX width={10} className="text-grey-400" />
        </button>
      </div>
    )
  );
};

export const ChangesSuggestedPopup = (props: { hideButton?: boolean }) => {
  const { hideButton } = props;

  const nav = useNavigate();

  return (
    <div className="z-10 flex flex-col bg-grey-200 p-4 gap-2.5 absolute rounded-lg bottom-4 right-4 shadow-action-button w-[300px]">
      <div className="flex flex-row items-center gap-1.5">
        <CheckCircledIcon color="#81CA67" />
        <span className="text-sm text-grey-800">
          Changes have been suggested
        </span>
      </div>

      <span className="text-sm text-grey-500 mb-2">
        The Lighthouse team has received your suggestions and will review them
        shortly.
      </span>

      {!hideButton && (
        <Button onClick={() => nav({ to: "/home" })} variant="secondary">
          Return to Home
        </Button>
      )}
    </div>
  );
};

const EditorRenderer = (props: {
  setInformationModalOpen: (value: boolean) => void;
  isBeneficiary: boolean;
  letterActionId: number;
  letterActionStatus: string;
  letterId: number;
  initialStatus: string;
  additionalNotes: string | null;
}) => {
  const {
    setInformationModalOpen,
    letterActionId,
    letterId,
    letterActionStatus,
    initialStatus,
    isBeneficiary,
    additionalNotes,
  } = props;

  const [status, setStatus] = useState<string>(initialStatus);

  const { provider, userId } = useEditorContext();

  const [hasSuggestedChanges, setHasSuggestedChanges] = useState<boolean>();

  const requestLetterActionChangesMutation = useMutation(
    requestLetterActionChanges
  )[1];

  const checkComments = useCallback(() => {
    const hasSubmittedComment = hasUserSubmittedComment(provider, userId);

    setHasSuggestedChanges(hasSubmittedComment);
  }, [provider, userId]);

  useEffect(() => {
    if (provider?.document == null) return;

    checkComments();

    provider.document.getArray("comments")?.observeDeep(checkComments);

    return () =>
      provider.document.getArray("comments")?.observeDeep(checkComments);
  }, [provider, checkComments]);

  const onCommentAdded = async () => {
    if (letterActionStatus === "sent") {
      const res = await requestLetterActionChangesMutation({
        letterActionId: letterActionId,
      });

      if (res.error) return;

      setStatus("changes_requested");
    }
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center overflow-hidden">
      {status === "changes_requested" && <ChangesSuggestedPopup />}
      {hasSuggestedChanges == false && status === "sent" && (
        <CommentHintPopup />
      )}
      <div className="w-full flex flex-row items-center justify-between px-6 h-20 mb-3 flex-shrink-0">
        <Link
          to="/home"
          className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center"
        >
          <Mark />
          <h1 className="font-serif font-[600] text-base tracking-[-0.5%]">
            Lighthouse
          </h1>
        </Link>

        <div className="flex flex-row items-center gap-3">
          <button
            onClick={() => setInformationModalOpen(true)}
            className="bg-grey-200 shadow-action-button h-8 w-8 rounded-full flex items-center justify-center"
          >
            <InfoCircledIcon color="white" width={20} />
          </button>

          {hasSuggestedChanges != null && (
            <SubmitReviewButton
              letterId={letterId}
              letterActionId={letterActionId}
              isBeneficiary={isBeneficiary}
              hasSuggestedChanges={hasSuggestedChanges}
              status={status}
              setStatus={setStatus}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row overflow-hidden">
        <div className="flex flex-col gap-4 overflow-hidden p-10 pb-0">
          {status === "approved" && (
            <div className="flex flex-row items-center gap-4 bg-grey-600 shadow-bubble p-5 w-[825px] rounded-lg text-sm text-grey-300">
              <div className="h-9 w-9 bg-[#D9D9D9] rounded-full flex items-center justify-center text-grey-300 text-lg">
                <FaRegCircleCheck />
              </div>

              <span>You have approved this letter.</span>
            </div>
          )}

          <div className="overflow-y-auto no-scrollbar pb-10">
            {status === "approved" && isBeneficiary && (
              <LetterReviewsTable letterId={letterId} />
            )}

            <TextEditor
              key={letterId + status}
              disabled={status === "approved"}
            />
          </div>
        </div>

        {status !== "approved" && (
          <div className="sticky top-0 overflow-y-auto no-scrollbar py-8">
            {additionalNotes != null && additionalNotes.trim().length > 0 && (
              <div className="w-[400px] flex flex-col gap-3 bg-grey-800 p-3 rounded-lg transition-all group/container ring-2 ring-grey-600 ring-inset mb-4">
                <p className="text-sm text-grey-200 font-medium">
                  Additional information:
                </p>

                <div
                  className="text-[13px] text-grey-300"
                  dangerouslySetInnerHTML={{
                    __html: additionalNotes,
                  }}
                ></div>
              </div>
            )}

            <TextEditorComments onCommentAdded={onCommentAdded} />
          </div>
        )}
      </div>
    </div>
  );
};

const LetterReviewEditor = (props: {
  data: ResultOf<typeof getLetterActionById>;
}) => {
  const { data } = props;

  const { loggedInUserId, userEntity } = useUserContext();

  const [informationModalOpen, setInformationModalOpen] = useState(
    data.getLetterAction.status === "sent"
  );

  const [{ data: companyMembers }] = useQuery({
    query: getCompanyMembers,
    variables: {
      id: data.getLetterAction.companyId ?? -1,
    },
    pause: data.getLetterAction.companyId == null,
  });

  const userName =
    userEntity != null
      ? userEntity.firstName + " " + userEntity.lastName
      : "You";

  const commentUserIdWhitelist = getCommentUserIdWhitelist(
    data,
    companyMembers
  );

  const letterTutorialModalShown = useTutorialModalState(
    (state) => state.letter.modalShown
  );

  return (
    loggedInUserId != null && (
      <div
        className="relative w-full h-full"
        style={{
          background: "url('/backgrounds/letter-review.webp')",
          backgroundBlendMode: "normal",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <SupportLettersTutorialModal />
        {letterTutorialModalShown && (
          <InformationModal
            isBeneficiary={data.getLetterAction.recipientType === "beneficiary"}
            beneficiaryName={data.getLetterAction.beneficiaryName}
            open={informationModalOpen}
            setOpen={setInformationModalOpen}
          />
        )}

        <div className="w-full h-full absolute bg-grey-800 bg-opacity-25 backdrop-blur-[2px]" />
        {data?.getLetterAction.letterId != null && (
          <EditorContextProvider
            letterId={data.getLetterAction.letterId}
            userId={loggedInUserId.toString()}
            userName={userName}
            commentUserIdWhitelist={commentUserIdWhitelist}
            mode="review"
            letterActionId={data.getLetterAction.id}
          >
            <EditorRenderer
              letterActionId={data.getLetterAction.id}
              letterId={data.getLetterAction.letterId}
              additionalNotes={data.getLetterAction.additionalNotes}
              letterActionStatus={data.getLetterAction.status}
              setInformationModalOpen={setInformationModalOpen}
              isBeneficiary={
                data.getLetterAction.recipientType === "beneficiary"
              }
              initialStatus={data.getLetterAction.status}
            />
          </EditorContextProvider>
        )}
      </div>
    )
  );
};

const LetterReview = () => {
  const { letterActionId } = Route.useParams();

  const { loggedInUserId } = useUserContext();

  const [{ data, fetching, error }] = useQuery({
    query: getLetterActionById,
    variables: {
      id: isNaN(parseInt(letterActionId)) ? -1 : parseInt(letterActionId),
    },
    requestPolicy: "network-only",
  });

  if (error != null) return <LetterActionNotFoundPage />;

  if (
    data?.getLetterAction.type != null &&
    data.getLetterAction.type !== "reviewApprove"
  )
    return (
      <LetterActionNotFoundPage>
        <p className="text-grey-200 font-normal">
          Did you mean to sign this letter?{" "}
          <Link
            to="/letter/sign/$letterActionId"
            search={{ reviewed: true }}
            params={{ letterActionId: letterActionId }}
            className="text-blue"
          >
            Sign Letter
          </Link>
        </p>
      </LetterActionNotFoundPage>
    );

  if (loggedInUserId == null || fetching || data == null)
    return (
      <div className="flex flex-col items-center text-center h-full justify-center">
        <Spinner />
      </div>
    );

  return <LetterReviewEditor data={data} />;
};

export const Route = createFileRoute("/_portal/letter/review/$letterActionId")({
  component: () => <LetterReview />,
});
